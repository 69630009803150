import React from "react";
import EChart from "../EChart";
import { t } from "i18next";

function LineEChart(props) {
  const {
    loading,
    gap = false,
    saveAsImg = true,
    tooltip = true,
    markPoint = true,
    averageLine = true,
    yAxisTitle,
    dataZoom = true,
    smooth = true,
    fulfilled = false,
    height,
    labelShow = false,
    labelData = ["Mar 2023", "Apr 2023", "May 2023", "Jun 2023", "Jul 2023", "Aug 2023", "Sep 2023", "Oct 2023"],
    dataSeries,
    width,
  } = props;

  const seriesData =
    dataSeries?.length === 0
      ? [
          {
            name: t("caymland.core.type.email"),
            data: [],
          },
        ]
      : dataSeries;

  const option = {
    toolbox: {
      show: true,
      itemSize: 11,
      iconStyle: {
        opacity: 0.58,
      },
      orient: "horizontal",
      top: "0%",
      right: "0%",
      feature: {
        dataView: {
          show: true,

          readOnly: false,
          optionToContent: function (opt) {
            const series = opt.series;
            let table =
              '<div style="display: flex; justify-content: center;">' +
              '<table style="width:90%;text-align:left;border-collapse:collapse;margin-top:10px;">' +
              "<thead><tr>" +
              `<th style="border:1px solid #ccc;padding:8px;background-color:#f8f8f8;text-align:center;">${t(
                "caymland.core.name"
              )}</th>`;
            series.forEach((s) => {
              table +=
                '<th style="border:1px solid #ccc;padding:8px;background-color:#f8f8f8;text-align:center;">' +
                s.name +
                "</th>";
            });
            table += "</tr></thead><tbody>";
            for (let i = 0; i < labelData.length; i++) {
              table += "<tr>" + '<td style="border:1px solid #ccc;padding:8px;">' + labelData[i] + "</td>";
              series.forEach((s) => {
                const dataItem = s?.data[i];
                const value = dataItem && typeof dataItem === "object" ? dataItem.value : dataItem;
                table +=
                  '<td style="border:1px solid #ccc;padding:8px;">' + (value === undefined ? "" : value) + "</td>";
              });
              table += "</tr>";
            }
            table += "</tbody></table></div>";
            return table;
          },
        },
        saveAsImage: { show: saveAsImg },
        // restore: { show: true },
      },
    },
    // tooltip: {
    //   show: tooltip,
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "line",
    //     label: {
    //       backgroundColor: "#6a7985",
    //     },
    //   },
    // },

    legend: {
      show: true,
      selectedMode: "multiple",
      data: dataSeries?.map((series) => series?.name),
    },

    xAxis: {
      data: labelData || [],
      boundaryGap: gap,
    },
    yAxis: {
      name: yAxisTitle,
    },
    series: seriesData?.map((series) => ({
      ...series,
      type: "line",
      smooth: smooth,
      areaStyle: {}, // Ensures the area under the line is always filled
      stack: fulfilled ? "Total" : null,
      label: {
        show: labelShow,
        position: "bottom",
        color: "grey",
      },
      emphasis: {
        focus: "series",
      },
      showBackground: true,
      symbolSize: 10,
      // markPoint: markPoint
      //   ? {
      //       data: [
      //         { type: "max", name: t("caymland.echart.max.value") },
      //         { type: "min", name: t("caymland.echart.min.value") },
      //       ],
      //     }
      //   : null,
      // markLine: averageLine
      //   ? {
      //       data: [{ type: "average", name: t("caymland.echart.average.value") }],
      //     }
      //   : null,
    })),

    lineStyle: {
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowBlur: 10,
    },
  };

  return <EChart option={option} loading={loading} height={height} width={width} />;
}

export default LineEChart;
