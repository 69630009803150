import React from "react";
import { Controller } from "react-hook-form";
import {
  Select,
  Text,
  TextArea,
  DatePicker,
  Label,
  ButtonGroup,
  TimePicker,
  LookupInput,
} from "../../../reusableComponents/Inputs";
import moment from "moment";
import CountriesSelect from "../../../reusableComponents/ReusableSelects/Countries";
import LocalesSelect from "../../../reusableComponents/ReusableSelects/Locales";
import RegionsSelect from "../../../reusableComponents/ReusableSelects/Regions";
import TimezoneSelect from "../../../reusableComponents/ReusableSelects/Timezone";
import { t } from "i18next";
import StaticCompanySelect from "../StaticCompanySelect";

const ViewFieldsForm = ({ field, control, watch, setValue, isW50 = false, errors }) => {
  const yesLabel = field?.properties?.yes || t("caymland.core.form.yes");
  const noLabel = field?.properties?.no || t("caymland.core.form.no");
  const toggleButtons = [
    { value: 3, label: "X" },
    { value: 0, label: noLabel },
    { value: 1, label: yesLabel },
  ];
  let content;
  let options = [];

  switch (field.type) {
    case "text":
    case "email":
    case "number":
    case "tel":
    case "url":
      content = (
        <div key={field.id} className={`w-full`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value, onBlur } }) => (
              <Text
                onBlur={onBlur}
                name={name}
                type={field.type}
                // label={field.label}
                value={value}
                onChange={onChange}
                placeholder={field.label}
                required={field.isRequired}
                errors={errors && errors[name]}
                marginBottom
              />
            )}
          />
        </div>
      );
      break;

    case "boolean":
      const defaultBooleanValue = field?.defaultValue == 1 ? 1 : field?.defaultValue == 0 ? 0 : 3;
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Convert null to 3 for display purposes
              const displayValue = value === "" ? 3 : value;

              return (
                <div>
                  {/* <Label name={name} required={field?.isRequired} errors={errors && errors[name]} /> */}
                  <ButtonGroup
                    buttons={toggleButtons}
                    defaultValue={defaultBooleanValue}
                    margin
                    value={displayValue} // Use displayValue for rendering
                    onChange={(event) => {
                      let newValue = parseInt(event.target.value, 10);
                      // Convert 3 back to null for submission
                      newValue = newValue === 3 ? "" : newValue;
                      onChange(newValue);
                    }}
                  />
                  {/* {<span style={{ color: "red", marginRight: "10px" }}>{field.isRequired ? "*" : ""}</span>} */}
                </div>
              );
            }}
          />
        </div>
      );
      break;

    case "date":
    case "datetime":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <DatePicker
                name={name}
                value={value ? moment(value).toDate() : null}
                // label={field.label}
                onChange={(date) => {
                  if (!date) {
                    onChange(null);
                  } else {
                    const formattedDate =
                      field.type === "datetime"
                        ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                        : moment(date).format("YYYY-MM-DD");
                    onChange(formattedDate);
                  }
                }}
                required={field.isRequired}
                placeholderText={t("caymland.core.select")}
                showTimeSelect={field.type === "datetime"}
                isClearable={false}
                marginBottom={false}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;

    case "country":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <CountriesSelect
                name={name}
                onChange={onChange}
                // label={field.label}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;
    case "locale":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <LocalesSelect
                name={name}
                onChange={onChange}
                value={value}
                // label={field.label}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;
    case "region":
      content = (
        <div key={field.id} className={`w-full`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <RegionsSelect
                name={name}
                onChange={onChange}
                // label={field.label}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );

      break;

    case "lookup":
      content = (
        <div key={field.id} className="w-full">
          {/* <Label
            name={field.name}
            // label={field.label}
            required={field?.isRequired}
            errors={errors && errors[field.name]}
          /> */}
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value, onBlur } }) => (
              <>
                <LookupInput
                  field={field}
                  control={control}
                  value={value}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  style={{
                    height: "40px", // Input height
                    width: "100%", // Full width
                    padding: "10px 14px", // Input padding
                    fontSize: "15px", // Input text size
                    backgroundColor: "#fff", // Input background color
                    border: "1px solid #ccc", // Default border
                    borderRadius: "4px", // Standard border-radius
                    boxShadow: "none", // No shadow by default
                    transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for focus state
                  }}
                />
              </>
            )}
          />
        </div>
      );

      break;

    case "select":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                margin
                // label={field.label}
                isRequired={field.isRequired}
                isMulti={field.type === "multiselect" ? true : false}
                options={options}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value}
                // value={value ? { label: value, value } : null}
              />
            )}
          />
        </div>
      );
      break;
    case "multiselect":
      if (Array.isArray(field?.properties?.list)) {
        // Map options, ensuring unique labels and values are preserved
        options = field.properties.list.map((i) => ({
          label: i.label || i.value, // Display the full value as a label if no specific label is provided
          value: i.value, // Full distinct value like 'Select^sel'
        }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({
          label: i, // Use full value as label
          value: i, // Use full value as value
        }));
      }

      content = (
        <div key={field.id} className={`w-full`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Ensure that value is always an array, even if there's a single value
              const valueArray = value && typeof value === "string" ? value.split("|") : value || [];

              // Map the selected options based on the value array
              const selectedOptions = options.filter((option) => valueArray.some((v) => v === option.value));

              return (
                <Select
                  name={name}
                  extraStyle
                  margin
                  isRequired={field.isRequired}
                  isMulti
                  options={options} // Pass the options
                  value={selectedOptions} // Ensure selectedOptions match options
                  onChange={(selectedOptions) => {
                    // Map selected options into the correct format for saving
                    const newValue = selectedOptions.map((option) => option.value).join("|");
                    onChange(newValue); // Save the selected values as a single string with '|' separator
                  }}
                />
              );
            }}
          />
        </div>
      );
      break;

    case "textarea":
      content = (
        <div key={field.id} className="w-full">
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value, onBlur } }) => (
              <textarea
                className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-rounded-[4px] tw-border tw-border-gray-300 tw-focus:ring-1 tw-focus:ring-blue-500 tw-focus:border-blue-500 "
                style={{ resize: "none" }}
                value={value}
                onChange={onChange}
                placeholder={field.label}
                required={field.isRequired}
              />
            )}
          />
        </div>
      );
      break;

    case "time":
      content = (
        <div key={field.id} className={`w-full `}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimePicker
                // label={name}
                name={field.alias}
                value={value}
                required={field.isRequired}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "timezone":
      content = (
        <div key={field.id} className={`w-full`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimezoneSelect
                name={name}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value ? { label: value, value } : null}
                required={field.isRequired}
              />
            )}
          />
        </div>
      );
      break;

    default:
      content = (
        <div className="pb-0 mb-0">
          <p>
            {t("caymland.core.needs.done")} ({field.type})
          </p>
        </div>
      );
  }
  switch (field.alias) {
    case "company":
      content = null;
      break;
    case "points":
      content = null;
      break;
  }
  return content;
};

export default ViewFieldsForm;
