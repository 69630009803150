import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Statistics from "./TabsComponents/Statistics";
import History from "./TabsComponents/History";
import AuditLog from "./TabsComponents/AuditLog";
import Notes from "./TabsComponents/Notes";
import SeeTicket from "./TabsComponents/SeeTicket";
import Files from "./TabsComponents/Files";
import {
  useGetReservationsQuery,
  useGetTranscationByIdQuery,
  useHistoryTabQuery,
  useNoteTabQuery,
} from "../../../../redux/api/contactsApi";
import Reservation from "./TabsComponents/Reservation";
import Transactions from "./TabsComponents/Transactions";

const Tabs = ({ tabs, statistics, id, reload, setReload }) => {
  const hasStatisticsData = () => statistics && Object.keys(statistics).length > 0;
  const [activeTab, setActiveTab] = useState(statistics ? "statistics" : "history");
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
  });

  const [auditLog, setAuditLog] = useState("0");
  const [showare, setShoware] = useState("0");
  const [files, setFiles] = useState("0");

  const [selectedEventTypes, setSelectedEventTypes] = useState([]);

  const { data, isFetching, refetch } = useHistoryTabQuery({ id, query, includeEvents: selectedEventTypes });
  const { data: reservationData } = useGetReservationsQuery(id);
  const { data: noteData } = useNoteTabQuery({ id, query });
  const { data: transactionData } = useGetTranscationByIdQuery({ id: id, q: query });

  const hasReservations = reservationData?.reservations && reservationData.reservations.length > 0;

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (hasStatisticsData()) {
      setActiveTab("statistics");
    } else {
      setActiveTab("history");
    }
  }, [statistics]);

  // Filter tabs to include "Reservations" only if there is reservation data
  const filteredTabs = tabs.filter((tab) => {
    if (tab.alias === "reservations") {
      return hasReservations;
    }
    return true;
  });

  const tabCounts = {
    notes: noteData?.total || "0",
    transactions: transactionData?.total || "0",
    history: data?.total || "0",
    audit_log: auditLog || "0",
    showare: showare || "0",
    reservations: reservationData?.reservations?.length || "0",
    files: files || "0",
  };

  return (
    <div>
      <Nav tabs>
        {/* Render Statistics Tab first if data is available */}
        {hasStatisticsData() && (
          <NavItem>
            <NavLink className={activeTab === "statistics" ? "active-bg" : ""} onClick={() => toggle("statistics")}>
              Statistics
            </NavLink>
          </NavItem>
        )}

        {/* Render dynamically filtered tabs */}
        {filteredTabs.map((tab, index) => (
          <NavItem key={index}>
            <NavLink className={activeTab === tab.alias ? "active-bg" : ""} onClick={() => toggle(tab.alias)}>
              {`${tab.name} (${tabCounts[tab.alias] || "0"})`}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* Render Statistics Content */}
        {hasStatisticsData() && (
          <TabPane tabId="statistics">
            {activeTab === "statistics" && (
              <Statistics statistics={statistics} id={id} data={data} setQuery={setQuery} query={query} />
            )}
          </TabPane>
        )}

        {/* Render History Content */}
        <TabPane tabId="history">
          {activeTab === "history" && (
            <History
              setReload={setReload}
              reload={reload}
              id={id}
              data={data}
              isFetching={isFetching}
              refetch={refetch}
              setQuery={setQuery}
              query={query}
              selectedEventTypes={selectedEventTypes}
              setSelectedEventTypes={setSelectedEventTypes}
            />
          )}
        </TabPane>

        {/* Render AuditLog Content */}
        <TabPane tabId="audit_log">
          {activeTab === "audit_log" && <AuditLog id={id} setAuditLog={setAuditLog} />}
        </TabPane>

        {/* Render Notes Content */}
        <TabPane tabId="notes">{activeTab === "notes" && <Notes id={id} />}</TabPane>

        {/* Render See Tickets Content */}
        <TabPane tabId="showare">{activeTab === "showare" && <SeeTicket id={id} setShoware={setShoware} />}</TabPane>

        {/* Render Reservations Content only if reservations exist */}

        <TabPane tabId="reservations">
          {activeTab === "reservations" && <Reservation reservationData={reservationData} />}
        </TabPane>
        <TabPane tabId="transactions">
          {activeTab === "transactions" && <Transactions id={id} />}
        </TabPane>

        {/* Render Files Content */}
        <TabPane tabId="files">
          {activeTab === "files" && (
            <Files id={id} tabData={tabs.filter((tab) => tab.alias === "files")} setFiles={setFiles} />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Tabs;
