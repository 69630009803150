import { Modal, ModalFooter, ModalBody, ModalHeader } from "react-bootstrap";
import { useMemo, createElement, useCallback, useEffect, useState } from "react";
import { EventsSource, EventsAction, SourceForm, EventForm } from "../index";
import { Back, Cancel, Save } from "../buttons";

import _ from "lodash";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ({ props, setProps, nodesData }) {
  const triggerMode =
    (props?.eventType === "lead.scorecontactscompanies" && props?.anchors === "no") ||
    (props?.params?.type === "lead.scorecontactscompanies" && props?.params?.anchors === "no")
      ? "interval"
      : "immediate";

  const defaultValues = { name: props?.params?.type, triggerMode: triggerMode, properties: {} };

  const [dynamicFields, setDynamicFields] = useState([]);

  const {
    watch,
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      useMemo(() => {
        const formProperties = dynamicFields.reduce((schema, field) => {
          if (field.name === "value") {
            schema["value"] = yup.mixed().when("operator", {
              is: (op) => op === "!empty",
              then: (schema) => schema.notRequired(),
              otherwise: (schema) => {
                return field.required ? schema.required(`${field.label} is required`) : schema.notRequired();
              },
            });
          } else {
            schema[field.name] = field.required
              ? yup.mixed().required(`${field.label} is required`)
              : yup.mixed().notRequired();
          }

          return schema;
        }, {});

        return yup.object({
          name: yup.string(),
          triggerMode: yup.string(),
          triggerInterval: yup.number().when("triggerMode", {
            is: "interval",
            then: (schema) => schema.required("Trigger interval is required when triggerMode is 'interval'"),
            otherwise: (schema) => schema.notRequired(),
          }),
          triggerIntervalUnit: yup.string().when("triggerMode", {
            is: "interval",
            then: (schema) => schema.required("Trigger interval unit is required when triggerMode is 'interval'"),
            otherwise: (schema) => schema.notRequired(),
          }),
          properties: yup.object(formProperties), // Dynamically validated fields
        });
      }, [triggerMode, dynamicFields]) // Recalculate schema when triggerMode or fields change
    ),
  });
  console.log(errors);

  console.log(watch());

  // Extract and update fields from formProperties dynamically
  useEffect(() => {
    const formProperties = watch("fields")?.formProperties;
    if (formProperties) {
      const extractedFields = Object.values(formProperties);
      setDynamicFields(extractedFields);
    }
  }, [watch("fields")?.formProperties]);

  console.log(dynamicFields);

  const Screens = useMemo(
    () => ({
      leadSource: {
        0: EventsSource,
        2: SourceForm,
      },
      lists: {
        2: SourceForm,
      },
      forms: {
        2: SourceForm,
      },
      events: {
        0: EventsSource,
        1: EventsAction,
        2: EventForm,
      },
      decision: {
        0: EventsSource,
        1: EventsAction,
        2: EventForm,
      },
      condition: {
        0: EventsSource,
        1: EventsAction,
        2: EventForm,
      },
      action: {
        0: EventsSource,
        1: EventsAction,
        2: EventForm,
      },
      notFound: () => <div>Component is not defined</div>,
    }),
    []
  );

  const buttons = useMemo(
    () => [
      {
        type: "back",
        name: t("caymland.modal.back"),
        rules: { 0: false, 1: true, 2: false },
        component: Back,
      },
      {
        type: "cancel",
        name: t("caymland.core.form.close"),
        rules: { 0: true, 1: true, 2: true },
        component: Cancel,
      },
      {
        type: "save",
        name: t("caymland.core.form.save"),
        rules: { 0: false, 1: false, 2: true },
        component: Save,
      },
    ],
    []
  );

  const Screen = useCallback(
    (args) => {
      if (!args || !args.action) return Screens.notFound();
      const { action, type, step } = args;
      const ScreenComponent = _.get(Screens, [type, step], Screens.notFound);
      return createElement(ScreenComponent, args);
    },
    [Screens]
  );

  const handleOnSelectEvent = useCallback(
    (type, eventType = null) => {
      const update = {};

      if (type) update.type = type;
      if (eventType) update.eventType = eventType;

      setProps((prevState) => ({
        ...prevState,
        step: prevState.step + (["lists", "forms"].includes(update.type) ? 2 : 1),
        ...update,
      }));
    },
    [setProps]
  );

  const handleCancel = useCallback(() => {
    console.log("Cancel");
    reset(defaultValues);
    setProps({ isOpen: false });
  }, [reset, setProps]);

  useEffect(() => {
    handleCancel();
  }, [reset]);

  const handleBack = useCallback(() => {
    if (props.step > 0) {
      reset(defaultValues);
      setProps((prevState) => ({ ...prevState, step: prevState.step - 1 }));
    }
  }, [props.step, reset, setProps]);

  const isHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  const eventDescription = watch("eventDescription");

  return (
    <div>
      <Modal
        show={props.isOpen}
        onHide={handleCancel}
        backdrop={"static"}
        size={props.step < 2 ? "md" : "lg"}
        centered={true}
      >
        <ModalHeader closeButton>
          <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <span style={{ fontSize: 18 }}>{watch("eventName") || t("caymland.pp.form.event")}</span>
            {eventDescription &&
              (isHTML(eventDescription) ? (
                <span dangerouslySetInnerHTML={{ __html: eventDescription }} />
              ) : (
                <span style={{ fontSize: 12 }}>{eventDescription}</span>
              ))}
          </div>
        </ModalHeader>
        <ModalBody>
          <Screen
            handleOnSelectEvent={handleOnSelectEvent}
            control={control}
            getValues={getValues}
            setValue={setValue}
            reset={reset}
            watch={watch}
            params={props}
            nodesData={nodesData}
            errors={errors}
            {...props}
          />
        </ModalBody>
        <ModalFooter>
          {buttons.map((button) => {
            if (button.rules[props.step]) {
              return createElement(button.component, {
                action: props.action,
                actions: props.actions,
                handleSubmit,
                handleCancel,
                defaultValues: defaultValues,
                handleBack,
                reset,
              });
            }
            return null;
          })}
        </ModalFooter>
      </Modal>
    </div>
  );
}
