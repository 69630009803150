import React from "react";
import EChart from "../EChart";
import { t } from "i18next";

function BarChart(props) {
  const {
    loading,
    saveAsImg = true,
    tooltip = true,
    dataView = true,
    markPoint = true,
    averageLine = true,
    yAxisTitle,
    dataZoom = true,
    title,
    percentage,
    subTitle,
    insideValue = true,
    labelData = ["Mar 2023", "Apr 2023", "May 2023", "Jun 2023", "Jul 2023", "Aug 2023", "Sep 2023", "Oct 2023"],
    dataSeries = [
      {
        name: t("caymland.core.type.email"),
        data: [],
      },
    ],
  } = props;
  const option = {
    title: {
      text: title,
      subtext: subTitle,
    },
    grid: {
      top: "10%",
      bottom: "20%",
      left: "5%",
      right: "3.5%",
    },
    tooltip: {
      show: tooltip,
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
      formatter:
        percentage &&
        function (params) {
          let res = `${params[0].axisValue}<br/>`;
          params.forEach((param) => {
            res += `${param.marker}${param.seriesName}: ${param.data.value}%<br/>`;
          });
          return res;
        },
    },
    legend: {
      data: dataSeries.map((series) => series.name),
    },
    toolbox: {
      show: true,
      orient: "horizontal",
      right: "0",
      top: "0",
      feature: {
        dataView: {
          show: dataView,
          readOnly: false,
          optionToContent: function (opt) {
            const series = opt.series;
            let table =
              '<div style="display: flex; justify-content: center;">' +
              '<table style="width:90%;text-align:left;border-collapse:collapse;margin-top:10px;">' +
              "<thead><tr>" +
              '<th style="border:1px solid #ccc;padding:8px;background-color:#f8f8f8;text-align:center;">Name</th>';
            series.forEach((s) => {
              table +=
                '<th style="border:1px solid #ccc;padding:8px;background-color:#f8f8f8;text-align:center;">' +
                s.name +
                "</th>";
            });
            table += "</tr></thead><tbody>";
            for (let i = 0; i < labelData.length; i++) {
              table += "<tr>" + '<td style="border:1px solid #ccc;padding:8px;">' + labelData[i] + "</td>";
              series.forEach((s) => {
                const dataItem = s.data[i];
                const value = dataItem && typeof dataItem === "object" ? dataItem.value : dataItem;
                table +=
                  '<td style="border:1px solid #ccc;padding:8px;">' + (value === undefined ? "" : value) + "</td>";
              });
              table += "</tr>";
            }
            table += "</tbody></table></div>";
            return table;
          },
        },
        magicType: { show: true, type: ["stack"] },
        // restore: { show: true },
        saveAsImage: { show: saveAsImg },
      },
    },

    calculable: true,
    xAxis: [
      {
        type: "category",
        data: labelData,
        axisPointer: {
          type: "shadow",
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        name: yAxisTitle,
      },
    ],
    // dataZoom: [
    //   {
    //     show: dataZoom,
    //     start: 0,
    //     end: 100,
    //   },
    //   {
    //     type: "inside",
    //     start: 0,
    //     end: 100,
    //   },
    //   {
    //     show: dataZoom,
    //     yAxisIndex: 0,
    //     filterMode: "empty",
    //     width: 30,
    //     height: "80%",
    //     showDataShadow: false,
    //     left: "93%",
    //   },
    // ],
    series: dataSeries.map((series) => ({
      ...series,
      type: "bar",
      data: series.data.map((dataPoint) => ({
        ...dataPoint,
        itemStyle: {
          color: dataPoint.itemStyle.color,
        },
      })),
      showBackground: true,
      label: {
        show: insideValue,
        position: "insideBottom",
        formatter: (params) => percentage && `${params.data.value}%`, // Updated formatter
      },
      // markPoint: markPoint
      //   ? {
      //       data: [
      //         {
      //           type: "max",
      //           name: t("caymland.echart.max.value"),
      //           label: {
      //             formatter: percentage && "{c}%",
      //           },
      //         },
      //         {
      //           type: "min",
      //           name: t("caymland.echart.min.value"),
      //           label: {
      //             formatter: percentage && "{c}%",
      //           },
      //         },
      //       ],
      //     }
      //   : null,
      // markLine: averageLine
      //   ? {
      //       data: [{ type: "average", name: t("caymland.echart.average.value") }],
      //     }
      //   : null,
    })),
  };

  return <EChart option={option} loading={loading} />;
}

export default BarChart;
