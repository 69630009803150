import DefaultFields from "./DefaultFields";
import RenderField from "./RenderField";
import _ from "lodash";
import StaticSmsFields from "./StaticSmsFields";

export default function Fields({ fields, control, watch, errors, reset, formFields, setId, extraOptions }) {
  // Check if the fields object has type "infobip.sms.send_text_sms"
  const isInfobipSMS = fields?.type === "infobip.sms.send_text_sms";

  return (
    <div style={{ paddingRight: 10, paddingLeft: 10 }}>
      <DefaultFields fields={fields} control={control} watch={watch} />

      {/* Render static select and buttons ONLY ONCE if fields.type is "infobip.sms.send_text_sms" */}
      {isInfobipSMS ? (
        <StaticSmsFields control={control} errors={errors} watch={watch} />
      ) : (
        <div style={{ paddingTop: 20 }}>
          {fields?.formProperties &&
            Object.values(fields?.formProperties).map((field, index) => (
              <RenderField
                key={field?.name + index}
                control={control}
                field={field}
                errors={errors}
                reset={reset}
                setId={setId}
                watch={watch}
                extraOptions={extraOptions}
              />
            ))}
        </div>
      )}
    </div>
  );
}
