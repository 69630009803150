import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { FaRegTimesCircle } from "react-icons/fa";
import Loading from "../loading";
import { Card, CardBody } from "reactstrap";
import { useGetImportsReqQuery } from "../../../redux/api/companiesApi";
import Header from "../Header/Header";
import Wrapper from "../Wrapper/Wrapper";
import CustomizedAccordions from "../Accordion/Accordion";
import ViewHistoryDetails from "../ViewHistoryDetails";
import CustomTable from "../../main-menu-sidebar/contacts/CustomTable/CustomTable";
// import {LineEChart} from "../../reusableComponents/ECharts/LineChart/LineEChart"
import LineChart from "../../reusableComponents/ECharts/LineChart/LineEChart";
import PieChart from "../ECharts/PieChart/PieChart";
import SidebarSkeleton from "../SidebarSkeleton";
import DetailsSkeleton from "../DetailsSkeleton";
import { t } from "i18next";

function View() {
  const navigate = useNavigate();
  const location = useLocation();

  const id = location?.state?.id || location?.state || useParams().id;

  const [loading, setLoading] = useState(false);
  const [importView, setImportView] = useState({});
  const [openDetails, setOpenDetails] = useState(false);
  const [failedRows, setFailedrRows] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [applyLoading, setApplyLoading] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{ backgroundColor: [], data: [], hoverBackgroundColor: [] }],
  });
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "id",
  });

  const isContactsView = location.pathname.includes("/contacts");
  const isCompaniesView = location.pathname.includes("/companies");

  const { data, isFetching, isLoading } = useGetImportsReqQuery(id);
  useEffect(() => {
    if (data?.imports && id) {
      const importData = { ...data.imports.import };
      setImportView({ importData }); // Directly setting the properties of importData as state
    }
    if (data?.charts) {
      setDataChart(data.charts.importedRowsChart);
      setRecentActivity(data.charts.logs);
      setChartData(data.charts.rowStatuses);
      setFailedrRows(data.charts.failedRows);
    }
  }, [data, id]);

  const formatDateTime = (date) => {
    if (!date || !moment(date).isValid()) {
      return null;
    }

    return moment(date).subtract(1, "hour").format("DD.MM.YY HH:mm");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return { color: "#33b5e5", label: t("caymland.lead.import.status.1") };
      case 2:
      case 6:
        return { color: "#007BFF", label: t("caymland.lead.import.status.2") };
      case 3:
        return { color: "#04B76B", label: t("caymland.lead.import.status.3") };
      case 4:
        return { color: "#DC3545", label: t("caymland.lead.import.status.4") };
      case 5:
      case 7:
        return { color: "#fb3", label: t("caymland.lead.import.status.5") };
      default:
        return { color: "", label: "" };
    }
  };
  const getDuration = (importView) => {
    if (!importView?.dateStarted || !importView?.dateEnded) {
      return null;
    }

    const dateStarted = new Date(importView.dateStarted);

    const dateEnded = new Date(importView.dateEnded);

    const diffMilliseconds = dateEnded - dateStarted;

    if (diffMilliseconds < 1000) {
      return "Less than 1 second";
    }

    const minutes = Math.floor(diffMilliseconds / (1000 * 60));
    const seconds = Math.floor((diffMilliseconds % (1000 * 60)) / 1000);

    return `${minutes} minutes ${seconds} seconds`;
  };

  const calculateImportSpeed = (importData) => {
    const dateStarted = importData.dateStarted;
    const dateEnded = importData.dateEnded;
    const lineCount = importData.lineCount;

    if (!dateStarted || !dateEnded || typeof lineCount !== "number") {
      return "0 rows/second";
    }

    const startDate = new Date(dateStarted);
    const endDate = new Date(dateEnded);
    const diffMilliseconds = endDate - startDate;
    const diffSeconds = diffMilliseconds / 1000;

    if (diffSeconds === 0) {
      return "0 rows/second";
    }

    const speed = lineCount / diffSeconds;
    return `${speed.toFixed(2)} rows/second`;
  };

  const importData = {
    dateStarted: importView?.importData?.dateStarted,
    dateEnded: importView?.importData?.dateEnded,
    lineCount: importView?.importData?.lineCount,
  };

  const importSpeed = calculateImportSpeed(importData);

  const calculateProgress = () => {
    const insertedCount = importView?.importData?.insertedCount;
    const ignoredCount = importView?.importData?.ignoredCount;
    const updatedCount = importView?.importData?.updatedCount;
    const lineCount = importView?.importData?.lineCount;

    const totalProcessed = insertedCount + updatedCount + ignoredCount;
    const progressPercentage = (totalProcessed / lineCount) * 100;

    if (isNaN(progressPercentage) || progressPercentage === 0) {
      return "0%";
    } else if (Math.floor(progressPercentage) === progressPercentage) {
      return `${progressPercentage}%`;
    } else {
      return `${progressPercentage.toFixed(2)}%`;
    }
  };

  const progressDisplay = calculateProgress();

  const getMappedFields = (fields) => {
    if (!fields) return "";

    return Object.entries(fields)
      .map(([key, value]) => `${key} = ${value}`)
      .join(", ");
  };

  const mappedFieldsString = getMappedFields(importView?.importData?.properties?.fields);

  const getDefaultOptions = (defaults) => {
    if (!defaults) return "";

    return Object.entries(defaults)
      .map(([key, value]) => {
        if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
          return `${key} = undefined`;
        }
        return `${key} = ${value}`;
      })
      .join(", ");
  };

  const defaultOptionsString = getDefaultOptions(importView?.importData?.properties?.defaults);

  const getHeaders = (headers) => {
    if (
      !headers ||
      !headers.importData ||
      !headers.importData.properties ||
      !Array.isArray(headers.importData.properties.headers)
    ) {
      return "";
    }

    const proHeaders = [...headers.importData.properties.headers];
    proHeaders[0] = proHeaders[0].replace("﻿", "");

    return proHeaders.join(", ");
  };

  const headersString = getHeaders(importView);

  const getParserProperties = (parser) => {
    if (!parser) return "";

    const delimiter = `delimiter = ${parser.delimiter || ""}`;
    const enclosure = `enclosure = ${parser.enclosure || "\\\\"}`;
    const escape = `escape = ${parser.escape || "\\\\"}`;
    const batchlimit = `batchlimit = ${parser.batchlimit || "100"}`;

    return `${delimiter}, ${enclosure}, ${escape}, ${batchlimit}`;
  };

  const parserPropertiesString = getParserProperties(importView?.importData?.properties?.parser);

  const handelNavigate = () => {
    if (isContactsView) {
      navigate("/contacts/import");
    } else if (isCompaniesView) {
      navigate("/companies/import/history");
    }
  };
  const buttons = [
    {
      name: "Close",
      title: t("caymland.core.close"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handelNavigate(),
    },
  ];
  const accordions = [
    {
      name: t("caymland.core.details"),
      component: (
        <ViewHistoryDetails
          getDuration={getDuration}
          importSpeed={importSpeed}
          progressDisplay={progressDisplay}
          mappedFieldsString={mappedFieldsString}
          defaultOptionsString={defaultOptionsString}
          importView={importView}
          parserPropertiesString={parserPropertiesString}
          headersString={headersString}
          formatDateTime={formatDateTime}
          getStatusColor={getStatusColor}
        />
      ),
    },
  ];

  const mappedData = data?.charts?.rowStatuses?.labels?.map((label) => {
    const parts = label?.split(",");
    const name = parts[0]?.trim();
    const percentagePart = parts[1]?.trim();
    const value = parseFloat(percentagePart?.replace("%", "")?.trim());

    return { value, name };
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "line",
        accessorFn: (row) => row.line,
        header: t("caymland.lead.import.csv.line.number"),
        enableColumnActions: false,
      },
      {
        accessorKey: "error",
        accessorFn: (row) => row.error,
        header: t("caymland.core.error.message"),
        enableColumnActions: false,
        Cell: ({ cell }) => <div style={{ whiteSpace: "pre-wrap" }}>{cell.row.original.error}</div>,
        muiTableHeadCellProps: {
          whiteSpace: "pre-wrap",
          sx: {
            whiteSpace: "pre-wrap",
          },
        },
      },
    ],
    []
  );

  return (
    <Fragment>
      {loading ? (
        <div className="flex items-center justify-center h-view">
          <Loading />
        </div>
      ) : (
        <>
          <Header
            buttons={buttons}
            title={importView?.importData?.originalFile}
            showIsPublished={true}
            isPublished={importView?.importData?.isPublished}
          />
          <Wrapper
            childrenClassOne={"wrapper-childOne"}
            childrenOne={
              isLoading ? (
                <DetailsSkeleton />
              ) : (
                <div className="w-full">
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div className={`${applyLoading ? "small-blur" : ""} col-9`} style={{ marginTop: "20px" }}>
                          <LineChart
                            labelData={data?.charts?.importedRowsChart?.labels}
                            dataSeries={data?.charts?.importedRowsChart?.datasets?.map((data) => {
                              return {
                                name: data.label,
                                data: data?.data?.map((num) => num),
                              };
                            })}
                          />
                        </div>
                        <div className="col-3">
                          <PieChart dataSeries={mappedData} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {Object.keys(failedRows).length > 0 ? (
                    <div className="w-full">
                      <span
                        style={{
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: 16,
                          marginBottom: "12px",
                        }}
                      >
                        {t("caymland.lead.import.failed.rows")}
                      </span>
                      <div>
                        <CustomTable
                          data={Object.values(failedRows).map((row) => row.properties)}
                          columns={columns}
                          query={query}
                          setQuery={setQuery}
                          actions={false}
                          linkNavigate={null}
                          mediumHeight
                        />
                      </div>
                    </div>
                  ) : (
                    <span style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: 16, marginBottom: "6px" }}>
                      {t("caymland.lead.import.no.failed.rows")}
                    </span>
                  )}

                  {/* <CustomTable data={""} viewTable={true} /> */}
                </div>
              )
            }
            childrenClassTwo={"wrapper-childTwoNoMargin"}
            childrenTwo={
              isLoading ? (
                <SidebarSkeleton applyPadding />
              ) : (
                <>
                  <CustomizedAccordions accordions={accordions} />{" "}
                  <div className="card-body activity-scroll px-20">
                    <div className="activity">
                      <div
                        style={{
                          marginBottom: "10px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        {t("caymland.core.recent.activity")}
                      </div>

                      {recentActivity?.map((activity, index) => (
                        <div key={index} className="d-flex">
                          <div
                            className="flex-shrink-0 gradient-round gradient-line-1"
                            style={{
                              marginRight: "15px",
                              background: activity.action === "create" ? "limegreen" : "#e9e7ed",
                            }}
                          >
                            {activity.action === "create" && <FaCheck />}
                          </div>
                          <div className="flex-grow-1">
                            <p className="mb-0 pb-0" style={{ fontSize: "12px" }}>
                              {activity?.action === "create" ? "Created By" : "Updated By"}{" "}
                              <strong>{activity?.userName}</strong>
                            </p>
                            <small style={{ fontSize: "12px" }}>
                              {moment(activity?.dateAdded).format("DD.MM.YY HH:mm")}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )
            }
          />
        </>
      )}
    </Fragment>
  );
}

export default View;
