import React, { useEffect, useState } from "react";
import { cn } from "../../../lib/utils";
import { t } from "i18next";
import { Separator } from "../../../components/ui/separator";
import { ResizablePanel } from "../../../components/ui/resizable";
import { styled, alpha } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  useAddFolderMailboxMutation,
  useDeleteFolderMailboxMutation,
  useUpdateFolderMailboxMutation,
} from "../../../redux/api/mailboxApi";
import AddEditDeleteFolder from "./AddEditDeleteFolder";
import FolderModal from "./FolderModal";
import { Alert, SlideAlert } from "../../reusableComponents/Alerts";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

const Folders = ({
  foldersMailbox,
  refetch,
  setQuery,
  setActiveFolder,
  activeFolder,
  handleDrop,
  setHoveredFolder,
  hoveredFolder,
  setMailIDs,
  isMobile,
  setView,
}) => {
  const currentUser = useSelector((state) => state?.auth.user);
  const { palette } = useTheme();

  const [folderName, setFolderName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState([]);

  const [addFolder] = useAddFolderMailboxMutation();
  const [updateFolder] = useUpdateFolderMailboxMutation();
  const [deleteFolder] = useDeleteFolderMailboxMutation();

  useEffect(() => {
    const expandedIds = findFolderAndParents(foldersMailbox, currentUser.defaultFolder);
    setExpandedFolders(expandedIds);
  }, [foldersMailbox, currentUser]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedFolders(itemIds);
  };

  const findFolderAndParents = (folders, folderId) => {
    const folder = folders.find((f) => f.id === folderId);
    if (!folder) return [];

    const parentIds = [];
    let currentFolder = folder;
    let safetyCounter = 0;

    while (currentFolder && currentFolder.parentId !== null && safetyCounter < 100) {
      const parentFolder = folders.find((f) => f.id === currentFolder.parentId);
      if (parentFolder) {
        parentIds.push(parentFolder.id);
        currentFolder = parentFolder;
      } else {
        break;
      }
      safetyCounter++;
    }

    if (safetyCounter >= 100) {
      console.error("Possible data inconsistency in folders structure");
      return [];
    }

    return [folder.id, ...parentIds].reverse();
  };

  const handleCRUDFolder = async (options, node) => {
    if (options === t("caymland.core.permissions.delete")) {
      if (node.parentId === null) {
        return;
      }
      Alert(`${t("caymland.badge_contact.form.confirmdelete")}`, t("caymland.message.delete.folder")).then(
        async (result) => {
          if (result.isConfirmed) {
            try {
              await deleteFolder(node.id);
              refetch();
            } catch (error) {}
          }
        }
      );
    } else {
      setModalOptions({ type: options === t("caymland.core.permissions.edit") ? "edit" : "add", node });
      setShowModal(true);
    }
  };

  const handleFolderClick = async (folder) => {
    setMailIDs([]);
    setActiveFolder(folder.name);
    setView("inbox");
    setQuery((prev) => ({
      pageIndex: 0,
      pageSize: 30,
      orderByDir: "DESC",
      orderBy: "id",
      where: [
        {
          col: "folder",
          expr: "eq",
          val: folder.id,
        },
      ],
    }));
  };

  const handleSave = async () => {
    const { type, node } = modalOptions;

    const data = {
      name: folderName,
      parentId: type === "edit" ? node?.parentId : node.id,
      isVisible: true,
      autoClean: null,
      lastClean: null,
    };

    try {
      if (type === "add") {
        await addFolder(data);
        refetch();
        setFolderName("");
        setShowModal(false);
      } else if (type === "edit") {
        await updateFolder({ id: node.id, ...data });
        refetch();
        setFolderName("");
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error saving folder:", error);
    }
  };

  const buildTree = (folders, parentId = null) => {
    return folders
      .filter((folder) => folder.parentId === parentId && folder.isVisible)
      .map((folder) => ({
        ...folder,
        children: buildTree(folders, folder.id),
      }));
  };

  const treeData = buildTree(foldersMailbox || []);

  const handleDragOver = (e, folderId) => {
    e.preventDefault();
    setHoveredFolder(folderId);
  };

  const handleDragLeave = (e, folderId) => {
    e.preventDefault();
    setHoveredFolder(null);
  };

  const getClassName = (nodeId, isActive) => {
    let baseClass = "";

    if (isActive) {
      baseClass += " tw-bg-[#e6e6e6] tw-rounded";
    }

    if (hoveredFolder === nodeId) {
      baseClass += " tw-border tw-border-dashed tw-border-[#b01a1a]";
    }

    return baseClass;
  };

  const renderTreeItems = (nodes) => {
    return nodes.map((node, index) => {
      const isActive = activeFolder === node.name;

      return (
        <CustomTreeItem
          key={node.id}
          itemId={node.id}
          nodeId={node.id}
          label={
            <div
              className={getClassName(node.id, isActive)}
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}
              onDragOver={(e) => handleDragOver(e, node.id)}
              onDragLeave={(e) => handleDragLeave(e, node.id)}
              onDrop={(e) => handleDrop(e, node.id)}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleFolderClick(node);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  fontSize: "13px",
                  marginLeft: "5px",
                }}
              >
                {node.name}
                <AddEditDeleteFolder
                  handleCRUDFolder={handleCRUDFolder}
                  node={node}
                  setFolderName={setFolderName}
                  setAlertMessage={setAlertMessage}
                  setShowAlert={setShowAlert}
                  refetch={refetch}
                  isActive={isActive}
                />
              </div>
            </div>
          }
        >
          {node.children.length > 0 && renderTreeItems(node.children)}
        </CustomTreeItem>
      );
    });
  };

  const CustomTreeItem = styled((props) => {
    return <TreeItem {...props} />;
  })(({ theme }) => ({
    color: theme.palette.mode === "light" ? theme.palette.grey[800] : theme.palette.grey[200],
    [`& .${treeItemClasses.content}`]: {
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.2, 0),
      "&:hover": {
        backgroundColor: "#e6e6e6 !important",
        color: "white !important",
      },
      [`& .${treeItemClasses.label}`]: {
        fontSize: "0.8rem",
        fontWeight: 500,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      borderRadius: "30%",
      backgroundColor: "#a6a3a3",
      border: "1px solid #a6a3a3",
      color: theme.palette.mode === "dark" && theme.palette.primary.contrastText,
      padding: theme.spacing(0, 1.0),
      maxHeight: "21px",
      maxWidth: "21px",
      "&:hover": {
        backgroundColor: "gray !important",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      transition: "height 300ms ease-in-out",
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 5,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
      transition: "opacity 300ms ease-in-out",
    },
  }));

  function ExpandIcon(props) {
    return (
      <KeyboardArrowRightIcon
        {...props}
        onClick={(e) => {
          props.onClick(e);
        }}
        sx={{ opacity: 0.8 }}
      />
    );
  }

  function CollapseIcon(props) {
    return (
      <KeyboardArrowDownIcon
        {...props}
        onClick={(e) => {
          props.onClick(e);
        }}
        sx={{ opacity: 0.8 }}
      />
    );
  }

  const content = (
    <>
      <SlideAlert message={alertMessage} show={showAlert} setShowAlert={setShowAlert} />

      <FolderModal
        folderName={folderName}
        setFolderName={setFolderName}
        showModal={showModal}
        setShowModal={setShowModal}
        handleSave={handleSave}
      />

      <ResizablePanel defaultSize={35} minSize={32} maxSize={45} className="tw-max-h-[90vh] tw-overflow-y-scroll">
        <div className={cn("tw-flex tw-h-[52px] tw-items-center tw-justify-center")}>
          <div
            className="tw-flex tw-gap-2 tw-items-center tw-justify-center "
            style={{ fontWeight: "bold", textTransform: "uppercase", fontSize: 16, color: palette?.text.primary }}
          >
            {t("caymland.mailbox.title")}
          </div>
        </div>
        <Separator />

        <ScrollArea className="tw-h-[90vh]">
          <Box sx={{ padding: "5px 5px 5px 0px" }}>
            <SimpleTreeView
              aria-label="customized"
              expandedItems={expandedFolders}
              onExpandedItemsChange={handleExpandedItemsChange}
              slots={{
                expandIcon: ExpandIcon,
                collapseIcon: CollapseIcon,
              }}
              sx={{
                overflowX: "auto",
                flexGrow: 1,
                width: "100%",
                "& .MuiTreeItem-iconContainer": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "background-color 150ms ease-in-out",
                },
                "& .MuiTreeItem-iconContainer:hover": {
                  backgroundColor: "#D55F5F !important",
                },
                "& .MuiTreeItem-content:hover": {
                  backgroundColor: "hsl(210, 40%, 96.1%) !important",
                  transition: "background-color 150ms ease-in-out, border-color 150ms ease-in-out",
                },
                "& .MuiTreeItem-content": {
                  padding: "0 !important",
                  paddingLeft: "5px !important",
                },
              }}
            >
              {renderTreeItems(treeData)}
            </SimpleTreeView>
          </Box>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </ResizablePanel>
    </>
  );

  return isMobile ? <div>{content}</div> : content;
};

export default Folders;
