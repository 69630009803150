import React, { useEffect, useState } from "react";
import { languages } from "../../../../reusableComponents/data";
import Button from "../../../../reusableComponents/Buttons/Button";
import { Label, Text, Select as ReusSelect, Checkbox, DatePicker } from "../../../../reusableComponents/Inputs";
import { retrieveCategories } from "../../../../reusableComponents/retriveCategoriesByBundle";
import { categoriesApi, useAddCategoryMutation } from "../../../../../redux/api/categoriesApi";
import { emailsApi, useGetEmailsQuery } from "../../../../../redux/api/emailsApi";
import { segmentsApi } from "../../../../../redux/api/segmentsApi";
import { t } from "i18next";
import { CategoryModalData } from "../../../../reusableComponents/ModalGenerator/ModalGeneratorData";
import ModalGenerator from "../../../../reusableComponents/ModalGenerator/ModalGenerator";
import emoji from "emoji-dictionary"; // Import the emoji-dictionary
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

function Sidebar({ modifiedData, setModifiedData, errors, setErrors, setOriginalData, originalData }) {
  const [translations, setTranslations] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState();
  const [selectLists, setSelectLists] = useState([]);
  const location = useLocation();
  const id = location?.state?.id || location?.state || useParams().id;

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "email",
  });
  const [newItem, setNewItem] = useState();

  const [addCategory] = useAddCategoryMutation();
  const [triggerCategories] = categoriesApi.useLazyGetCategoriesOptionsQuery();

  useEffect(() => {
    if (newItem) {
      setModifiedData({ ...modifiedData, category: newItem });
    }
  }, [newItem]);

  const [q, setQ] = useState({
    pageIndex: 0,
    pageSize: 1000,
    orderByDir: "ASC",
    orderBy: "id",
    where: [],
  });
  const [triggerSegmentsQuery] = segmentsApi.useLazyGetSegmentsQuery();
  const { data } = useGetEmailsQuery(q);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [triggerEmailVersions] = emailsApi.useLazyGetEmailVersionsQuery();
  const [version, setVersion] = useState();

  const handleVersion = (e) => {
    if (!e) {
      // Handle clearing of the select
      setVersion(null); // Clear the version state
      setModifiedData((prevData) => ({
        ...prevData,
        customJson: null, // Optionally clear customJson or handle as required
      }));
      return;
    }

    setModifiedData((prevData) => ({
      ...prevData,
      customJson: e.value, // Update with the selected value
    }));
    setVersion(e);
  };

  const retrieveVersions = (inputValue, bundle, includeGlobal = true) =>
    new Promise(async (resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newTimeout = setTimeout(async () => {
        const query = {};

        if (inputValue.length) {
          query.date = inputValue;
        }

        const response = await triggerEmailVersions({ id: id, q: query });
        console.log(response);
        if (!response.error) {
          console.log(response);
          const emailVersionsSelect = Object.values(response.data.versions).map((version) => ({
            label:
              moment(version.dateAdded).format("DD.MM.YYYY HH:mm") +
              " " +
              version?.createdBy?.firstName +
              " " +
              version?.createdBy?.lastName,
            value: version.customJson,
          }));
          resolve(emailVersionsSelect);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  //function for getting conact segments from api
  function isArrayContainingObject(array) {
    return array.some((element) => element !== null && typeof element === "object" && !Array.isArray(element));
  }
  useEffect(() => {
    if (Array.isArray(modifiedData.lists) && isArrayContainingObject(modifiedData.lists)) {
      const formattedLists = modifiedData.lists.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setSelectLists(formattedLists);

      // Update modifiedData.lists with just the IDs
      const listIds = formattedLists.map((item) => item.value);
      if (JSON.stringify(listIds) !== JSON.stringify(modifiedData.lists)) {
        setModifiedData((prev) => ({
          ...prev,
          lists: listIds,
        }));
        setOriginalData((prev) => ({
          ...prev,
          lists: listIds,
        }));
      }
    }
  }, [modifiedData?.lists, originalData?.lists]);
  const handleSelectChangee = (selectedOptions) => {
    setSelectLists(selectedOptions);
    const updatedLists = selectedOptions.map((option) => option.value);
    setModifiedData((prev) => ({
      ...prev,
      lists: updatedLists,
    }));
  };

  //Segments
  const retrieveSegments = (inputValue) =>
    new Promise(async (resolve) => {
      if (!triggerSegmentsQuery) resolve([]);

      const query = {
        where: [
          {
            expr: "eq",
            col: "isPublished",
            val: 1,
          },
        ],
      };

      if (inputValue.length) {
        query.search = inputValue;
      }
      const response = await triggerSegmentsQuery(query).unwrap();

      if (!response?.errors) {
        const segmentsData = response?.data;
        const segmentsArray = segmentsData?.map((field) => ({
          label: field?.name,
          value: field?.id,
        }));

        resolve(segmentsArray);
      }
    });

  // Use useEffect to update options when modifiedData.language or other dependencies change
  useEffect(() => {
    if (data?.data) {
      let response = data?.data;
      let groupList = [];
      setTranslations([]);

      response
        .filter(
          (item) =>
            !item.translationParent &&
            item.emailType === modifiedData.emailType &&
            item.id !== modifiedData.id &&
            item.language !== modifiedData.language
        )
        .forEach((item) => {
          const groupKey = item.language;
          if (!groupList[groupKey]) {
            groupList[groupKey] = [];
          }
          groupList[groupKey].push({
            label: item.name,
            value: item.id,
          });
        });

      const groupedOptions = Object.keys(groupList).map((key) => ({
        label: key,
        options: groupList[key],
      }));

      setTimeout(() => {
        setTranslations(groupedOptions);
      }, 1000);
    }
  }, [modifiedData?.language, modifiedData?.emailType, data, modifiedData?.translationParent]);

  useEffect(() => {}, [translations]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name" || name === "subject") {
      if (value.length > 0) {
        delete errors[name];
        setErrors({ ...errors });
      } else {
        errors[name] = true;
        setErrors({ ...errors });
      }
    }

    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSelectChangeCategory = (selectedOption) => {
    if (selectedOption && selectedOption.value === "create_new") {
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
      }));
      return;
    }
    setModifiedData({ ...modifiedData, category: selectedOption });
  };

  useEffect(() => {
    // Reset selectedTranslation when translations change to ensure dropdown refresh
    setSelectedTranslation(null);
  }, [translations]);

  const handleSelectChange = (e, name) => {
    if (e === null) {
      setModifiedData({ ...modifiedData, [name]: "" });
    } else {
      setModifiedData({ ...modifiedData, [name]: e });
    }
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setModifiedData({
      ...modifiedData,
      [name]: checked,
    });
  };

  //Is a translation of
  const handleTranslationOfChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.value;
    const isStaticOption = selectedValues === "staticOption";
    const parentKey = modifiedData.emailType === "template" ? "templateTranslationParent" : "segmentTranslationParent";

    if (isStaticOption) {
      setSelectedTranslation(selectedValues);
      setModifiedData({
        ...modifiedData,
        [parentKey]: null,
      });
    } else {
      setSelectedTranslation(selectedValues);
      setModifiedData({
        ...modifiedData,
        [parentKey]: selectedValues,
      });
    }
  };

  const getEmoji = (text) => {
    return text ? text.replace(/:([a-zA-Z0-9_+-]+):/g, (match, p1) => emoji.getUnicode(p1) || match) : text;
  };

  console.log(modifiedData);

  return (
    <>
      <ModalGenerator
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        content={CategoryModalData}
        addItem={addCategory}
        setNewItem={setNewItem}
      />
      <Label
        name="chooseEmailType"
        required={true}
        label={t("caymland.email.send.emailtype")}
        tooltip={t("caymland.email.type.template.description")}
      />

      <div className="flex items-center justify-center w-full" style={{ marginBottom: "25px" }}>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "campaignEmail",
                title: `${t("caymland.campaign.campaign")} ${t("caymland.email.email")}`,
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    emailType: "template",
                    lists: null,
                    segmentTranslationParent: null,
                    templateTranslationParent: null,
                  });
                },
              },
            ]}
            variant={modifiedData?.emailType === "template" ? "contained" : "outlined"}
            textFormat="capitalize"
          />
        </div>
        <div className="w-50 parent-100">
          <Button
            buttons={[
              {
                name: "segmentEmail",
                title: `${t("caymland.email.report.segment")} ${t("caymland.email.email")}`,
                onClick: () => {
                  setModifiedData({
                    ...modifiedData,
                    emailType: "list",
                    segmentTranslationParent: null,
                    templateTranslationParent: null,
                  });
                },
              },
            ]}
            variant={modifiedData?.emailType === "list" ? "contained" : "outlined"}
            textFormat="capitalize"
            isDisabled={modifiedData?.emailType === "template" && modifiedData?.sentCount > 0}
          />
        </div>
      </div>

      <div className="mb-20px">
        <Text
          name="subject"
          errors={errors?.subject}
          required={true}
          value={getEmoji(modifiedData?.subject) || ""}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.mailbox.compose.label.subject")}
        />
      </div>
      <div className="mb-20px">
        <Text
          name="name"
          errors={errors?.name}
          required={true}
          value={modifiedData?.name || ""}
          onChange={(e) => handleInputChange(e)}
          label={t("caymland.email.form.internal.name")}
        />
      </div>
      {modifiedData?.emailType === "list" &&
        !modifiedData?.templateTranslationParent &&
        !modifiedData?.segmentTranslationParent &&
        !modifiedData.translationParent && (
          <div style={{ marginBottom: "15px" }}>
            <ReusSelect
              name="lists"
              label={t("caymland.email.form.list")}
              errors={errors?.lists}
              isMulti={true}
              isClearable
              required={true}
              options={retrieveSegments}
              value={selectLists}
              onChange={handleSelectChangee}
            />
          </div>
        )}

      <div style={{ marginBottom: "15px" }}>
        {!modifiedData.translationParent && (
          <ReusSelect
            name="category"
            bundle="email"
            label={t("caymland.page.tracking.form.category")}
            options={retrieveCategories}
            trigger={triggerCategories}
            value={modifiedData?.category ?? null}
            onChange={(e) => handleSelectChangeCategory(e)}
            isClearable
            isCreatable
          />
        )}
      </div>
      <div style={{ marginBottom: "15px" }}>
        <ReusSelect
          name="language"
          label={t("caymland.core.language")}
          options={languages.map((lang) => ({ label: t(lang.labelKey), value: lang.value }))}
          value={modifiedData?.language}
          onChange={(e) => handleSelectChange(e.value, "language")}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <ReusSelect
          tooltip={t("caymland.core.form.translation_parent.help")}
          label={t("caymland.core.form.translation_parent")}
          name="isTranslation"
          options={translations}
          value={translations
            .flatMap((group) => group.options)
            .find(
              (option) =>
                option.value === (selectedTranslation ? selectedTranslation : modifiedData?.translationParent?.id)
            )}
          placeholder={`${t("caymland.core.select")}...`}
          onChange={(selectedOptions) => handleTranslationOfChange(selectedOptions)}
        />
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2">
        <Checkbox
          name="isPublished"
          label={t("caymland.core.form.published")}
          value={modifiedData?.isPublished || false}
          onChange={(e) => handleToggleChange(e)}
        />
        {!modifiedData.translationParent && (
          <Checkbox
            name="Double-Opt-In"
            label={t("caymland.email.double_opt_in")}
            value={modifiedData?.doubleOptIn || false}
            onChange={(e) =>
              setModifiedData({
                ...modifiedData,
                doubleOptIn: e.target.checked,
              })
            }
            tooltip={t("caymland.email.double_opt_in.tooltip")}
          />
        )}
        {!modifiedData.translationParent && (
          <>
            <Checkbox
              name="ignoreDNC"
              label={t("caymland.email.ignore_dnc")}
              value={modifiedData?.ignoreDNC || false}
              onChange={(e) =>
                setModifiedData({
                  ...modifiedData,
                  ignoreDNC: e.target.checked,
                })
              }
              tooltip={t("caymland.email.ignore_dnc.tooltip")}
            />

            <Checkbox
              name="publishCommand"
              label={t("caymland.email.publish_command")}
              value={modifiedData?.publishCommand || false}
              onChange={(e) =>
                setModifiedData({
                  ...modifiedData,
                  publishCommand: e.target.checked,
                })
              }
              tooltip={t("caymland.email.publish_command.help")}
            />
          </>
        )}
      </div>

      {modifiedData?.publishCommand && (
        <>
          <div
            className="warning-text p-no-margin tw-mt-[10px] tw-mb-[10px]"
            style={{ display: "block", position: "relative" }}
          >
            <p>{t("caymland.email.publish_command.help")}</p>
          </div>
          <div className=" mt-10">
            <DatePicker
              label={t("caymland.core.form.publishup")}
              name={"Publish at (date/time)"}
              value={modifiedData?.publishUp ? new Date(modifiedData?.publishUp) : null}
              onChange={(e) => setModifiedData({ ...modifiedData, publishUp: e })}
              maxDate={modifiedData?.publishDown}
              showTimeSelect
            />
          </div>
          <div className="">
            <DatePicker
              label={t("caymland.core.form.publishdown")}
              name={"Unpublish at (date/time)"}
              value={modifiedData?.publishDown ? new Date(modifiedData?.publishDown) : null}
              onChange={(e) => setModifiedData({ ...modifiedData, publishDown: e })}
              minDate={modifiedData?.publishUp}
              showTimeSelect
            />
          </div>
        </>
      )}
      <div className="tw-mt-2">
        {id && (
          <ReusSelect
            options={retrieveVersions}
            value={version?.value ?? null}
            label="Version"
            isClearable={false}
            onChange={(e) => handleVersion(e)}
          />
        )}
      </div>
      {modifiedData?.translationParent && (
        <div
          className="warning-text p-no-margin tw-mt-[10px] tw-mb-[10px]"
          style={{ display: "block", position: "relative" }}
        >
          <p>
            {t("caymland.core.form.translation_parent")} "{modifiedData?.translationParent?.name}"
          </p>
        </div>
      )}
    </>
  );
}

export default Sidebar;
