import { Controller } from "react-hook-form";
import { DatePicker, Text } from "../../../../reusableComponents/Inputs";
import React from "react";

export default function ({ control, watch, ...props }) {
  return (
    <Controller
      control={control}
      name={`triggerDate`}
      render={({ field: { name, onChange, value } }) => (
        <DatePicker label="" name={name} value={value} onChange={onChange} showTimeSelect />
      )}
    />
  );
}
