import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import StaticCompanySelect from "./StaticCompanySelect";
import { debounce } from "lodash";
import {
  Text,
  ButtonGroup,
  DatePicker,
  Label,
  Select,
  TextArea,
  TimePicker,
  LookupInput,
} from "../../reusableComponents/Inputs";
import { useGetCustomFieldSearchQuery } from "../../../redux/api/contactsApi";
import { t } from "i18next";
import moment from "moment";
import CountriesSelect from "../../reusableComponents/ReusableSelects/Countries";
import LocalesSelect from "../../reusableComponents/ReusableSelects/Locales";
import RegionsSelect from "../../reusableComponents/ReusableSelects/Regions";
import TimezoneSelect from "../../reusableComponents/ReusableSelects/Timezone";

export const FieldsRenderSection = ({
  aliases,
  filteredFields,
  title,
  isW50 = false,
  setContactImg,
  setFormChanged,
  control,
  watch = () => {},
  getValues,
  noBorder = false,
  errors,
  setValue,
  setDisabled,
}) => {
  return (
    <div className="group-fields-style" style={{ border: noBorder ? "none" : "1px solid #135f9517" }}>
      <h5 style={{ fontWeight: "600" }}>{title}</h5>
      <div className="row">
        {filteredFields
          ?.sort((a, b) => a.order - b.order)
          .filter((field) => {
            return Array.isArray(aliases) ? aliases.includes(field.alias) : true;
          })
          .map((field) => {
            if (field.alias === "company") {
              return (
                <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
                  <StaticCompanySelect control={control} watch={watch} setValue={setValue} getValues={getValues} />
                </div>
              );
            } else {
              return (
                <FiledsRenderReusable
                  key={field.id}
                  field={field}
                  isW50={isW50}
                  setContactImg={setContactImg}
                  setFormChanged={setFormChanged}
                  control={control}
                  watch={watch}
                  errors={errors}
                  setDisabled={setDisabled}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

const FiledsRenderReusable = ({ field, isW50, control, watch, errors, setDisabled = () => {} }) => {
  const yesLabel = field?.properties?.yes || t("caymland.core.form.yes");
  const noLabel = field?.properties?.no || t("caymland.core.form.no");
  const toggleButtons = [
    { value: 3, label: "X" },
    { value: 0, label: noLabel },
    { value: 1, label: yesLabel },
  ];
  const [query, setQuery] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [initialValue, setInitialValue] = useState(watch(field.alias) || "");
  const { data } = useGetCustomFieldSearchQuery(query, { skip: !query });

  const fieldValue = watch(field.alias);

  useEffect(() => {
    if (!field.isUniqueIdentifier || !["text", "email", "tel", "number", "url"].includes(field.type)) return;

    const debouncedSearch = debounce((value) => {
      if (typeof value === "string" && value.trim() !== "" && value !== initialValue) {
        setQuery(`${field.alias}=${encodeURIComponent(value)}`);
      } else {
        setQuery("");
        setShowFeedback(false);
      }
    }, 1000); 

    // ✅ Only trigger the search if `fieldValue` is a valid string
    if (typeof fieldValue === "string") {
      debouncedSearch(fieldValue);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [fieldValue, field.isUniqueIdentifier, field.alias, initialValue]);

  console.log(data);

  // ✅ Handle API response (Only show feedback if the value exists)
  useEffect(() => {
    if (query && data && Array.isArray(data) && data.length > 0) {
      setShowFeedback(true);
      setDisabled(true);
    } else {
      setShowFeedback(false);
      setDisabled(false);
    }
  }, [data, query]);

  let content;
  let options = [];

  switch (field.type) {
    case "text":
    case "number":
    case "tel":
    case "url":
    case "email":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Text
                name={name}
                type={field.type}
                value={value}
                onChange={onChange}
                label={field.label}
                required={field.isRequired}
                labelHidden={true}
                errors={errors && errors[name]}
              />
            )}
          />
          {showFeedback && (
            <div className="tw-mt-2 tw-text-[14px] tw-text-gray-600">
              <span>{t("caymland.lead.exists.by.field")}:</span>
              {data &&
                data?.map((item, index) => (
                  <span key={item.id}>
                    <a
                      href={`/contacts/view/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-text-black tw-ml-1 hover:tw-text-gray-800 hover:tw-underline"
                    >
                      {item.firstname} {item.lastname}
                    </a>
                    {index < data.length - 1 && <span className="tw-text-black">,</span>}
                  </span>
                ))}
            </div>
          )}
        </div>
      );
      break;

    case "boolean":
      const defaultBooleanValue = field?.defaultValue == 1 ? 1 : field?.defaultValue == 0 ? 0 : 3;
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Label name={name} label={field.label} required={field?.isRequired} errors={errors && errors[name]} />
                <ButtonGroup
                  buttons={toggleButtons}
                  defaultValue={defaultBooleanValue}
                  value={value}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value, 10);
                    onChange(!isNaN(newValue) ? newValue : event.target.value);
                  }}
                />
              </>
            )}
          />
        </div>
      );
      break;

    case "date":
    case "datetime":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <DatePicker
                label={field.label}
                required={field.isRequired}
                name={name}
                isClearable={false}
                value={value}
                showTimeSelect={field.type === "datetime" ? true : false}
                onChange={(date) => {
                  // Format the date to "YYYY-MM-DD HH:mm:ss"
                  const formattedDate =
                    field.type === "datetime"
                      ? moment(date).format("YYYY-MM-DD HH:mm:ss")
                      : moment(date).format("YYYY-MM-DD");
                  onChange(formattedDate); // Use the formatted date
                }}
              />
            )}
          />
        </div>
      );
      break;

    case "country":
    case "locale":
    case "region":
      const Component =
        field.type === "country" ? CountriesSelect : field.type === "locale" ? LocalesSelect : RegionsSelect;
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Component
                label={field.label}
                name={name}
                onChange={onChange}
                value={value}
                required={field.isRequired}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;

    case "lookup":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value, onBlur } }) => (
              <>
                <LookupInput
                  field={field}
                  control={control}
                  value={value}
                  label={field.label}
                  onChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  style={{
                    height: "40px", // Input height
                    width: "100%", // Full width
                    padding: "10px 14px", // Input padding
                    fontSize: "15px", // Input text size
                    backgroundColor: "#fff", // Input background color
                    border: "1px solid #ccc", // Default border
                    borderRadius: "4px", // Standard border-radius
                    boxShadow: "none", // No shadow by default
                    transition: "border-color 0.2s ease, box-shadow 0.2s ease", // Smooth transition for focus state
                  }}
                />
              </>
            )}
          />
        </div>
      );

      break;

    case "select":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                label={field.label}
                isRequired={field.isRequired}
                isMulti={field.type === "multiselect" ? true : false}
                options={options}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value}
                errors={errors && errors[name]}
              />
            )}
          />
        </div>
      );
      break;
    case "multiselect":
      if (Array.isArray(field?.properties?.list)) {
        options = field.properties.list.map((i) => ({ label: i.label, value: i.value }));
      } else if (typeof field?.properties?.list === "string") {
        options = field.properties.list.split("|").map((i) => ({ label: i, value: i }));
      }

      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""}`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => {
              // Transform the value back to an array of option objects
              const selectedOptions = options.filter((option) => value?.includes(option.value));

              return (
                <Select
                  name={name}
                  label={field.label}
                  isRequired={field.isRequired}
                  errors={errors && errors[name]}
                  isMulti={field.type === "multiselect"}
                  options={options}
                  value={selectedOptions} // Set the selected options as the value
                  onChange={(selectedOptions) => {
                    // Transform the selected options into an array of their values
                    const newValue = selectedOptions.map((option) => option.value);

                    // Call the original onChange method with the new array of values
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
        </div>
      );
      break;

    case "textarea":
      content = (
        <div key={field.id} className="w-50 w-full small-editor">
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TextArea
                name={name}
                errors={errors && errors[name]}
                label={field.label}
                required={field.isRequired}
                data={value ?? ""}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "time":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimePicker
                label={name}
                name={field.alias}
                errors={errors && errors[name]}
                value={value}
                required={field.isRequired}
                onChange={onChange}
              />
            )}
          />
        </div>
      );
      break;

    case "timezone":
      content = (
        <div key={field.id} className={`${isW50 ? "w-50" : ""} mb-20px`}>
          <Controller
            control={control}
            name={field.alias}
            render={({ field: { name, onChange, value } }) => (
              <TimezoneSelect
                errors={errors && errors[name]}
                label={field.alias}
                name={name}
                onChange={(selectedOption) => {
                  onChange(selectedOption ? selectedOption.value : "");
                }}
                value={value ? { label: value, value } : null}
                required={field.isRequired}
              />
            )}
          />
        </div>
      );
      break;

    default:
      content = (
        <div className="pb-0 mb-0">
          <p>
            {t("caymland.core.needs.done")} ({field.type})
          </p>
        </div>
      );
  }
  return <>{content}</>;
};

export default FiledsRenderReusable;
