import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cleanHtmlContent from "../../../../../reusableComponents/cleanHtmlContent";
import { useEmailTestingQuery } from "../../../../../../redux/api/emailsApi";
import Wrapper from "../../../../../reusableComponents/Wrapper/Wrapper";
import { Select } from "../../../../../reusableComponents/Inputs";
import { contactsApi } from "../../../../../../redux/api/contactsApi";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Tooltip } from "@mui/material";
import DetailsSkeleton from "../../../../../reusableComponents/DetailsSkeleton";
import SidebarSkeleton from "../../../../../reusableComponents/SidebarSkeleton";
import Button from "../../../../../reusableComponents/Buttons/Button";
import { t } from "i18next";
import { FaRegTimesCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { AiOutlineDesktop, AiOutlineTablet, AiOutlineMobile } from "react-icons/ai"; // Icons for preview modes

import emoji from "emoji-dictionary"; // Import the emoji-dictionary

function EmailTesting(props) {
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [contactId, setContactId] = useState("");
  const [emailHTMLContent, setEmailHTMLContent] = useState(""); // Store cleaned HTML content
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [previewMode, setPreviewMode] = useState("monitor"); // State to manage the preview mode
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const { data, refetch, isFetching, isLoading } = useEmailTestingQuery({ id, contactId });
  const [triggerContactsQuery] = contactsApi.useLazyGetContactsQuery();

  // This effect will be triggered when data is available
  useEffect(() => {
    if (data && data?.content) {
      const cleanedHtml = cleanHtmlContent(data?.content);

      // Inject a CSS reset to the cleaned HTML content
      const resetCSS = `
        <style>
          /* CSS reset to avoid conflicts with the app styles */
          body, h1, h2, h3, h4, h5, h6, p, div, span, a {
            margin: 0;
            padding: 0;
            font: inherit;
            color: inherit;
            background: none;
            border: none;
            outline: none;
            text-decoration: none;
          }
  
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            line-height: 1.6;
            padding: 10px;
          }
        </style>
      `;

      setEmailHTMLContent(`${resetCSS}${cleanedHtml}`); // Set the cleaned HTML content with reset
    }
  }, [id, data]);

  const retrieveContacts = (inputValue, bundle, includeGlobal = true) =>
    new Promise((resolve, reject) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      const newTimeout = setTimeout(async () => {
        const query = {
          pageIndex: 0,
          pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
          orderByDir: "desc",
          orderBy: "last_active",
          search: inputValue,
        };

        const response = await triggerContactsQuery(query);

        if (!response.error) {
          const contactsData = response?.data?.data;
          const contactsArray = contactsData
            .map((contact) => {
              const firstname = contact?.fields?.all?.firstname?.trim() || "";
              const lastname = contact?.fields?.all?.lastname?.trim() || "";

              // Only include contacts that have at least one non-empty name field
              if (firstname || lastname) {
                return {
                  label: `${firstname} ${lastname}`.trim(),
                  value: contact?.id,
                };
              }
              return null;
            })
            .filter(Boolean); // Remove null values from the array

          resolve(contactsArray);
        } else {
          reject();
        }
      }, 500);

      setDebounceTimeout(newTimeout);
    });

  const handleChange = (option) => {
    setSelectedOption(option);
    setContactId(option?.value);
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => navigate(-1),
    },
  ];

  const mobileButtons = [
    {
      name: "close",

      icon: <AiOutlineDesktop size={15} />,
      onClick: () => setPreviewMode("monitor"),
    },
    {
      name: "close",

      icon: <AiOutlineTablet size={15} />,
      onClick: () => setPreviewMode("tablet"),
    },
    {
      name: "close",

      icon: <AiOutlineMobile size={15} />,
      onClick: () => setPreviewMode("phone"),
    },
  ];

  // Set the iframe width based on the selected preview mode
  const getIframeStyle = () => {
    switch (previewMode) {
      case "tablet":
        return { width: "768px", height: "80vh" }; // Tablet view (768px width)
      case "phone":
        return { width: "360px", height: "80vh" }; // Phone view (360px width)
      default:
        return { width: "100%", height: "80vh" }; // Default to monitor (full width)
    }
  };
  const getEmoji = (text) => {
    return text ? text.replace(/:([a-zA-Z0-9_+-]+):/g, (match, p1) => emoji.getUnicode(p1) || match) : text;
  };

  return (
    <Fragment>
      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "10px" }}>
        <Button buttons={buttons} />
      </div>
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        style={{ minHeight: "93vh", maxHeight: "93vh", overflowY: "auto" }}
        childrenOne={
          isLoading ? (
            <DetailsSkeleton />
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="preview-controls">
                {/* <Button buttons={mobileButtons} /> */}
                <button
                  onClick={() => setPreviewMode("monitor")}
                  className="preview-button monitor-button"
                  style={{
                    background: previewMode == "monitor" && "black",
                    transform: previewMode == "monitor" && "scale(1.1)",
                  }}
                >
                  <AiOutlineDesktop color={`${previewMode == "monitor" ? "white" : "black"}`} size={20} />
                </button>
                <button
                  style={{
                    background: previewMode == "tablet" && "black",
                    transform: previewMode == "tablet" && "scale(1.1)",
                  }}
                  onClick={() => setPreviewMode("tablet")}
                  className="preview-button tablet-button"
                >
                  <AiOutlineTablet color={`${previewMode == "tablet" ? "white" : "black"}`} size={20} />
                </button>
                <button
                  style={{
                    background: previewMode == "phone" && "black",
                    transform: previewMode == "phone" && "scale(1.1)",
                  }}
                  onClick={() => setPreviewMode("phone")}
                  className="preview-button phone-button"
                >
                  <AiOutlineMobile color={`${previewMode == "phone" ? "white" : "black"}`} size={20} />
                </button>
              </div>

              {/* Display responsive HTML content in iframe */}
              <iframe
                title="email-preview"
                style={{ ...getIframeStyle(), border: "1px solid #ccc", alignSelf: "center" }} // Adjust iframe style based on preview mode
                srcDoc={emailHTMLContent} // Inject the cleaned HTML content
              />
            </div>
          )
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          isLoading ? (
            <SidebarSkeleton />
          ) : (
            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
              <Select
                label="Wählen Sie einen Kontakt"
                options={retrieveContacts}
                onChange={handleChange}
                value={selectedOption}
                margin
              />
              {selectedOption?.label && (
                <>
                  <div
                    style={{
                      borderTop: "1px solid gainsboro",
                      paddingTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "4px",
                    }}
                  >
                    <div style={{ width: "48%" }}>
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>Email Template rendered for:</span>
                    </div>
                    <div style={{ width: "50%" }} className="d-flex items-center gap-5px mr-5">
                      <span className="result-component" style={{ minWidth: "90%" }}>
                        <a rel="noreferrer" href={`/contacts/view/${selectedOption?.value}`} target="_blank">
                          {selectedOption?.label}
                        </a>
                      </span>
                      <Tooltip title="Refresh template" arrow>
                        <i style={{ margin: 0, padding: 0 }} onClick={() => refetch()}>
                          <AutorenewIcon
                            style={{ fontSize: "20px", color: "#135f95", cursor: "pointer" }}
                            className="selectIcon"
                          />
                        </i>
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
              {selectedOption?.label && data && (
                <div
                  style={{
                    borderTop: "1px solid gainsboro",
                    paddingTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>Sender info</span>

                  <span>Betreff:</span>
                  <span>
                    <b>{getEmoji(data?.subject)}</b>
                  </span>

                  <span>Absendername:</span>
                  <span>
                    <b>{data?.fromName}</b>
                  </span>

                  <span>Absendende E-Mail-Adresse:</span>
                  <span>
                    <b>{data?.fromAddress}</b>
                  </span>
                </div>
              )}
            </div>
          )
        }
      />
    </Fragment>
  );
}

export default EmailTesting;
