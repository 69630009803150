import { t } from "i18next";
import React, { useMemo, useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { useSelector } from "react-redux";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import {
  useDeleteUploadFileMutation,
  useFilesTabQuery,
  useFilesUploadTabMutation,
  useDownloadFileMutation,
} from "../../../../../redux/api/contactsApi";
import { ActionButtons } from "../../../../reusableComponents/tableComponents/ActionButtons";

const Files = ({ id, tabData, setFiles }) => {
  const token = useSelector((state) => state?.auth?.token);

  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    orderBy: "uf.dateAdded",
  });

  const { data, isFetching, refetch } = useFilesTabQuery({ id, query });

  useEffect(() => {
    if (data?.total) {
      setFiles(data?.total);
    }
  }, [data]);
  const [deleteUploadFile] = useDeleteUploadFileMutation();
  const [uploadFile] = useFilesUploadTabMutation();
  const [downloadFile] = useDownloadFileMutation();

  const getUploadParams = async ({ file }) => {
    const body = new FormData();
    body.append("file", file);

    try {
      console.log("Uploading file:", file);
      const response = await uploadFile({ id, body });
      refetch();
      console.log("Upload successful:", response);
      return { meta: { status: "done", uploadUrl: response } };
    } catch (error) {
      console.error("Upload failed:", error);

      // Dropzone needs error messages formatted this way.
      return { meta: { status: "error", errorMessage: error.message || "Upload failed" } };
    }
  };

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
    return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "fileName",
        accessorFn: (row) => {
          const displayName =
            row.fileOriginalName.length > 30 ? row.fileOriginalName.substring(0, 30) + "..." : row.fileOriginalName;

          const handleFileClick = async (e) => {
            e.preventDefault();
            try {
              const response = await downloadFile(row.fileName).unwrap();
              const blob = new Blob([response], { type: response.type });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = row.fileOriginalName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            } catch (error) {
              console.error("Failed to download file", error);
            }
          };

          return (
            <a href="#" onClick={handleFileClick}>
              {displayName}
            </a>
          );
        },
        header: t("caymland.core.name"),
        enableColumnActions: false,
      },
      {
        accessorKey: "files",
        accessorFn: (row) => row.typeFile,
        header: t("caymland.integration.Twitter.share.via"),
        enableColumnActions: false,
      },
      {
        accessorKey: "size",
        accessorFn: (row) => formatFileSize(row.size),
        header: t("caymland.mailbox.list.table.field.size"),
        enableColumnActions: false,
      },
      {
        accessorKey: "added",
        accessorFn: (row) => row?.owner?.firstName + " " + row?.owner?.lastName,
        header: t("caymland.lead.segments.contacts.added"),
        enableColumnActions: false,
      },
      {
        accessorKey: "dateAdded",
        accessorFn: (row) => moment(row?.dateAdded).format("DD.MM.YY HH:mm"),
        header: t("caymland.showare.field.event.showare_event_date_added"),
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    [downloadFile]
  );

  const actionButtons = ActionButtons({
    link: "files",
    clone: false,
    edit: false,
    deleteItem: deleteUploadFile,
    refetch: refetch,
  });

  return (
    <div style={{ padding: "15px", display: "flex", flexDirection: "column", gap: "10px" }}>
      <div className="w-full">
        <div className="p-no-margin">
          <p className="font-13">{t("caymland.asset.asset.form.file.upload", { max: tabData[0].maxSize })}</p>
          <form
            className="dropzone digits mt-10px"
            id="singleFileUpload"
            style={{ background: "white" }}
            action="/upload.php"
          >
            <div className="dz-message needsclick h-200-dropzone">
              <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={({ meta, file }, status) => {
                  if (status === "error_upload_params") {
                    console.log("Forcing success state...");
                    meta.status = "done"; // ✅ Override Dropzone’s misinterpretation
                  }

                  if (status === "done") {
                    console.log("File uploaded successfully:", file);
                  }
                }}
                accept="*"
                showPreview={false}
                showUploadButton={false}
                maxFiles={1}
                multiple={false}
                inputContent={t("caymland.asset.drop.file.here")}
                canRestart={false}
                styles={{
                  dropzoneActive: { borderColor: "green" },
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <CustomTable
        data={data || []}
        columns={columns}
        actionButtons={actionButtons}
        query={query}
        setQuery={setQuery}
        footer
        isFetching={isFetching}
        linkNavigate={null}
      />
    </div>
  );
};

export default Files;
