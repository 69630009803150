import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TbRefresh } from "react-icons/tb";
import { SlideAlert, Success, handleCancel } from "../../reusableComponents/Alerts";
import { useEffect } from "react";
import QueryFiltersReusible from "../../reusableComponents/ReusableQueryFilters/QueryFiltersReusible";
import { FaCheckDouble, FaRegTimesCircle } from "react-icons/fa";
import { AiOutlineSave } from "react-icons/ai";
import { Checkbox, Text, TextArea } from "../../reusableComponents/Inputs";
import Header from "../../reusableComponents/Header/Header";
import Wrapper from "../../reusableComponents/Wrapper/Wrapper";
import Button from "../../reusableComponents/Buttons/Button";
import {
  useAddSegmentsCountMutation,
  useAddSegmentsMutation,
  useGetFilterOptionsQuery,
  useGetDevicesQuery,
  useGetSegmentsOptionsQuery,
} from "../../../redux/api/segmentsApi";
import { useGetCampaignsOptionsQuery } from "../../../redux/api/campaignsApi";
import { useGetEmailsOptionsQuery } from "../../../redux/api/emailsApi";
import { useGetTagsOptionsQuery, useGetTagsQuery } from "../../../redux/api/tagsApi";
import { useGetStagesOptionsQuery, useGetStagesQuery } from "../../../redux/api/stagesApi";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useUpdateSegmentsToFoldersMutation } from "../../../redux/api/segmentsApi";
import { useGetGlobalCategoriesQuery } from "../../../redux/api/categoriesApi";

const filterQuery = (q, newObject) => {
  newObject.condition = q.combinator.toUpperCase();
  newObject.rules = q.rules.map((rule, index) => {
    if (rule.hasOwnProperty("rules")) {
      return filterQuery(q.rules[index], new Object());
    } else {
      return {
        id: rule.field.field,
        field: rule.field.field,
        type: rule.field.type,
        input: rule.field.input,
        object: rule.field.object || "lead",
        operator: rule.operator,
        value: typeof rule.value === "string" ? rule.value.toLowerCase() : rule.value,
      };
    }
  });
  return newObject;
};

export function hasOperatorWithTilde(obj) {
  if (obj.operator && obj.operator === "~") {
    return true;
  }
  if (obj.operator === "empty" || obj.operator === "not_empty") {
    return false;
  }
  if (obj.value === "") {
    return true;
  }
  if ((obj.operator === "empty" || obj.operator === "not_empty") && obj.value === "") {
    return false;
  }
  if (Array.isArray(obj.rules)) {
    const foundOperator = obj.rules.find(hasOperatorWithTilde);

    if (foundOperator) {
      return true;
    }
  }
  return false;
}

const transformDeviceData = (data) => {
  return Object.entries(data).map(([key, label]) => ({
    value: key,
    label: label,
  }));
};

const generateOptions = (dataToFilter) => {
  if (dataToFilter && Array.isArray(dataToFilter.data)) {
    const publishedData = dataToFilter.data.filter((x) => x.isPublished === true);

    return publishedData.map((x) => ({
      value: x.id.toString(),
      label: x.name,
    }));
  }
  return [];
};

const generateTags = (dataToFilter) => {
  if (dataToFilter && Array.isArray(dataToFilter.data)) {
    return dataToFilter?.data.map((x) => ({
      value: x.id.toString(),
      label: x.tag,
    }));
  }
  return [];
};

function AddNewSegment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState({
    combinator: "and",
    rules: [
      {
        field: "",
        id: "",
        operator: "",
        value: "",
        valueSource: "",
      },
    ],
  });

  const newSegment = useRef({
    isGlobal: false,
    isPublished: true,
    isPreferenceCenter: false,
    folderId: location.state,
    name: "",
  });

  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [description, setDescription] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isPreferenceCenter, setIsPreferenceCenter] = useState(false);
  const [fields, setFields] = useState([]);
  const [treffer, setTreffer] = useState();
  const [error, setError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [addSegmentCount, { isLoading }] = useAddSegmentsCountMutation();
  const [addSegment, { isLoading: isAddLoading }] = useAddSegmentsMutation();
  const { data, isFetching } = useGetFilterOptionsQuery();
  const { data: campaignsData } = useGetCampaignsOptionsQuery();
  const { data: segmentsData } = useGetSegmentsOptionsQuery("limit=200");
  const { data: emailsData } = useGetEmailsOptionsQuery();
  const { data: tagsData } = useGetTagsOptionsQuery();
  const { data: stagesData } = useGetStagesOptionsQuery();
  const { data: devicesData } = useGetDevicesQuery();
  const { data: globalCategory } = useGetGlobalCategoriesQuery();

  const deviceBrands = devicesData && devicesData.device_brand ? transformDeviceData(devicesData.device_brand) : [];
  const deviceOSes = devicesData && devicesData.device_os ? transformDeviceData(devicesData.device_os) : [];
  const deviceTypes = devicesData && devicesData.device_type ? transformDeviceData(devicesData.device_type) : [];

  useEffect(() => {
    const fetchFilterOptions = async () => {
      const filters = data?.data;

      const generateFilterObject = (filterName, filterData) => {
        return {
          label: filterName,
          options: Object.entries(filterData).map(([key, value]) => ({
            name: key,
            label: value.label,
            object: value.object,
            operators: Object.entries(value.operators).map(([key2, value2]) => ({
              name: key2,
              label: value2,
            })),
            ...value.properties,
            group: filterName,
          })),
        };
      };

      const emptyFilter = {
        label: t("caymland.segment.empty.filter"),
        options: [
          {
            group: "Leerer_Filter",
            label: t("caymland.segment.empty.filter"),
            name: "Leerer Filter",
            operators: [
              { name: "not_empty", label: t("caymland.core.isempty") },
              { name: "equals", label: t("caymland.lead.list.form.operator.equals") },
            ],
            type: "text",
          },
        ],
      };
      if (!filters) {
        return;
      }
      const tempFields = Object.entries(filters).map(([key, value]) => generateFilterObject(key, value));

      setFields([emptyFilter, ...tempFields]);
    };

    fetchFilterOptions();
  }, [data]);

  const init = () => {
    const element = document.getElementsByClassName("name-segment-add")[0];
    if (element) {
      element.innerText = "";
    } else {
      console.error("Element with class 'name-segment-add' not found.");
    }

    newSegment.current = {
      isGlobal: false,
      isPublished: true,
      folderId: location.state,
      isPreferenceCenter: false,
      name: "",
    };
    setQuery({
      combinator: "and",
      rules: [],
    });
    setName("");
    setAlias("");
    setDescription("");
    setIsGlobal(false);
    setIsPublished(true);
    setIsPreferenceCenter(false);
  };

  const [updateSegmentToFolder] = useUpdateSegmentsToFoldersMutation();

  const saveAndClose = async (params) => {
    let filters = {};
    filters.valid = newSegment.current.isPublished;
    filterQuery(query, filters);
    newSegment.current["filters"] = filters;

    if (filters.rules.length === 0 || hasOperatorWithTilde(filters)) {
      if (newSegment.current.name === "") {
        setError(true);
      }
      setShowAlert(true);
      setAlertMessage(t("caymland.core.alert.message.filter"));
      setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3500);
      return;
    }

    if (newSegment.current.name === "") {
      setError(true);
      setShowAlert(true);
      setAlertMessage(t("caymland.core.error.not.valid"));
      setTimeout(() => {
        setAlertMessage("");
        setShowAlert(false);
      }, 3000);
      return;
    }

    const response = await addSegment(newSegment.current).unwrap();

    if (response) {
      if (location.state) {
        const response2 = updateSegmentToFolder({ id: location.state, itemId: response.list.id });
      }
      Success(
        `${t("caymland.webhook.label.success")} ${t("caymland.message.type.successfully", {
          type: "created",
        })}`
      );
      init();
      if (params === "save") {
        navigate(`/segments/view/${response.list?.id}`);
      } else {
        navigate(`/segments/edit/${response.list?.id}`);
      }
    }
  };

  const handleTreffer = async () => {
    setTreffer();
    try {
      let filters = {};
      filters.valid = newSegment.current.isPublished;
      // filters.valid = true;
      filterQuery(query, filters);
      newSegment.current["filters"] = filters;

      const requestBody = {
        result: {
          ...filters,
        },
      };

      const response = await addSegmentCount(requestBody).unwrap();
      setTreffer(response?.segmentCount?.count);
    } catch (error) {}
  };

  const buttons = [
    {
      name: "close",
      title: t("caymland.core.form.cancel"),
      icon: <FaRegTimesCircle size={15} />,
      onClick: () => handleCancel("/segments", navigate),
    },
    {
      name: "save",
      title: t("caymland.core.form.saveandclose")?.replace(/&amp;/g, "&"),
      icon: <AiOutlineSave size={15} />,
      onClick: () => saveAndClose("save"),
    },
    {
      name: "apply",
      title: t("caymland.core.form.apply"),
      icon: <FaCheckDouble size={15} />,
      onClick: () => saveAndClose(""),
    },
  ];

  return (
    <>
      <SlideAlert show={showAlert} message={alertMessage} setShowAlert={setShowAlert} />
      <Header buttons={buttons} title={t("caymland.lead.list.header.new")} loading={isAddLoading} />
      <Wrapper
        childrenClassOne={"wrapper-childOne"}
        childrenOne={
          <>
            <QueryFiltersReusible
              query={query}
              setQuery={setQuery}
              loading={isFetching}
              fields={fields}
              campaignData={campaignsData}
              segmentData={segmentsData}
              emailData={emailsData}
              tagOptions={tagsData}
              stageOptions={stagesData}
              deviceBrands={deviceBrands}
              deviceOSes={deviceOSes}
              deviceTypes={deviceTypes}
              globalCategory={globalCategory}
            />
            <div className="d-flex w-100" style={{ justifyContent: "end", marginTop: "20px" }}>
              <Button
                loading={isLoading}
                buttons={[
                  {
                    name: "treffer",
                    title: `${treffer || 0} Treffer`,
                    icon: <TbRefresh size={15} />,
                    onClick: () => handleTreffer(),
                  },
                ]}
              />
            </div>
          </>
        }
        childrenClassTwo={"wrapper-childTwo"}
        childrenTwo={
          <>
            <div className="flex items-center gap-10">
              <div className="w-100">
                <Text
                  name="name"
                  value={name}
                  errors={error}
                  onChange={(e) => {
                    newSegment.current["name"] = e.target.value;
                    setName(e.target.value);
                    setError(false);
                  }}
                  label="Name"
                  required={true}
                />
              </div>
              <div className="w-100">
                <Text
                  name="Alias"
                  value={alias}
                  onChange={(e) => {
                    newSegment.current["alias"] = e.target.value;
                    setAlias(e.target.value);
                  }}
                  label="Alias"
                  tooltip={t("caymland.lead.list.help.alias")}
                />
              </div>
            </div>
            <div className="w-full small-editor">
              <TextArea
                label={t("caymland.lottery.table.row.description")}
                data={description ?? ""}
                onChange={(data) => {
                  setDescription(data);
                  newSegment.current["description"] = data;
                }}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-[10px]">
              <div className="switch-holder">
                <Checkbox
                  name="visible-on-forms"
                  value={isGlobal}
                  onChange={(e) => {
                    newSegment.current["isGlobal"] = e.target.checked;
                    setIsGlobal(!isGlobal);
                  }}
                  label={t("caymland.lead.list.form.isglobal")}
                  tooltip={t("caymland.lead.list.form.isglobal.tooltip")}
                />
              </div>
              <div className="switch-holder">
                <Checkbox
                  name="visible-on-forms1"
                  value={isPreferenceCenter}
                  onChange={(e) => {
                    newSegment.current["isPreferenceCenter"] = e.target.checked;
                    setIsPreferenceCenter(!isPreferenceCenter);
                  }}
                  label={t("caymland.lead.list.form.isPreferenceCenter")}
                  tooltip={t("caymland.lead.list.form.isPreferenceCenter.tooltip")}
                />
              </div>
              <div class="switch-holder">
                <Checkbox
                  name="visible-on-forms2"
                  value={isPublished}
                  onChange={(e) => {
                    newSegment.current["isPublished"] = e.target.checked;
                    setIsPublished(!isPublished);
                  }}
                  label={t("caymland.core.form.published")}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
}

export default AddNewSegment;
