import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { DatePicker as DataSelect, Text } from "../Inputs";
import { t } from "i18next";
import { useSelector } from "react-redux";
import Moment from "moment";

const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
const ValueEditorReusuble = (props) => {
  const {
    fieldData,
    value,
    field,
    handleOnChange,
    fields,
    campaignData,
    segmentData,
    emailData,
    tagOptions,
    stageOptions,
    deviceBrands,
    deviceOSes,
    deviceTypes,
    globalCategory,
  } = props;
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [localValue, setLocalValue] = useState(value);
  const [data, setData] = useState();
  // const debouncedHandleOnChange = useRef(debounce(handleOnChange, 1000)).current;
  const countryData = useSelector((state) => state.settings.data.countries);
  const localeData = useSelector((state) => state.settings.data.locales);
  const groupedOptions = useSelector((state) => state.settings.data.regions);

  useEffect(() => {
    const newValue = Array.isArray(value) ? value.join(",") : value;
    setLocalValue(newValue);

    if (typeof newValue === "string") {
      const initialSelectedIds = newValue.split(",").filter((id) => id.trim() !== "");
      setSelectedCampaignIds(initialSelectedIds);
    }
  }, [value, field.type]);

  function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  }

  function getOptions(field) {
    if (isPlainObject(field.list)) {
      // It's an object, proceed with mapping
      return Object.entries(field.list).map(([key, value]) => ({
        value: key,
        label: value,
      }));
    }
    if (
      Array.isArray(field.list) &&
      (JSON.stringify(field.list) === JSON.stringify(["Nein", "Ja"]) ||
        JSON.stringify(field.list) === JSON.stringify(["No", "Yes"]))
    ) {
      return field.list.map((value) => {
        return {
          value: value == "Nein" || value == "No" ? 0 : 1,
          label: value,
        };
      });
    }

    if (field.type === "locale") {
      return localeData || [];
    }
    if (field.type === "country") {
      return countryData || [];
    }
    if (field.type === "globalcategory") {
      return globalCategory || [];
    }
    if (field.type === "region") {
      return groupedOptions || [];
    }
    if (field.type === "campaign") {
      return campaignData || [];
    }
    if (field.type === "leadlist") {
      return segmentData || [];
    }
    if (field.type === "lead_email_received") {
      return emailData || [];
    }
    if (field.type === "tags") {
      return tagOptions || [];
    }
    if (field.type === "stage") {
      return stageOptions || [];
    }
    if (field.type === "device_brand") {
      return deviceBrands || [];
    }
    if (field.type === "device_os") {
      return deviceOSes || [];
    }
    if (field.type === "device_type") {
      return deviceTypes || [];
    }

    // Handle generic list options
    const options = field.list || findOptionsByField(field.field, fields);
    return Array.isArray(options) ? options : [];
  }

  function findOptionsByField(field, dataArray) {
    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].options) {
        for (let j = 0; j < dataArray[i].options.length; j++) {
          if (dataArray[i].options[j].name && dataArray[i].options[j].name === field) {
            let list = dataArray[i].options[j].list || [];
            return transformListToListOptions(list);
          }
        }
      }
    }
    return [];
  }

  function transformListToListOptions(list) {
    if (Array.isArray(list) && list.length > 0 && typeof list[0] === "object") {
      return list;
    }

    if (Array.isArray(list) && list.length > 0 && typeof list[0] === "string") {
      const transformedList = list.map((item, index) => ({ label: item, value: String(index) }));
      return transformedList;
    }

    if (typeof list === "object") {
      const transformedList = Object.entries(list).map(([key, value]) => ({
        value: key,
        label: value,
      }));
      return transformedList;
    }

    return [];
  }
  const isMulti =
    props.operator === "including" ||
    props.operator === "excluding" ||
    props.operator == "in" ||
    props.operator == "!in";

  function getValueForType(type, value, field) {
    switch (type) {
      case "country":
        return countryData.find((country) => country.value === value);
      case "locale":
        return localeData.find((locale) => locale.value === value);
      case "region":
        return groupedOptions.flatMap((group) => group.options).find((option) => option.value === value);
      case "multiselect":
        if (Array.isArray(value)) {
          return getOptions(field)?.filter((option) => value.includes(option.value));
        } else if (typeof value === "string") {
          const valuesArray = value.split(",");
          return getOptions(field)?.filter((option) => valuesArray.includes(option.value));
        } else {
          return [];
        }
      case "boolean":
        return getOptions(field)?.find((option) => option.value == value);
      case "integer":
        return getOptions(field)?.find((option) => option.value === String(value));
      case "select":
        if (isMulti) {
          if (Array.isArray(value)) {
            return getOptions(field)?.filter((option) => value.includes(option.value));
          } else if (typeof value === "string") {
            // Ensure we split the string and return an array
            const valuesArray = value.split(",").map((v) => v.trim());
            return getOptions(field)?.filter((option) => valuesArray.includes(option.value));
          } else {
            return [];
          }
        } else {
          // Single select should return a single object, not an array
          return getOptions(field)?.find((option) => option.value == value) || null;
        }

      default:
        return null;
    }
  }

  const isDisabled =
    props.operator === "!empty" || props.operator === "empty" || props.isDisabled || props.operator === "not_empty";

  const updatedValue = isDisabled ? " " : getValueForType(field.type, value, field);

  const getSelectValue = () => {
    switch (field.type) {
      case "campaign":
        return selectedCampaignIds.map((id) => campaignData.find((option) => option.value == id));
      case "leadlist":
        return selectedCampaignIds.map((id) => segmentData.find((option) => option.value == id));
      case "lead_email_received":
        return selectedCampaignIds.map((id) => emailData.find((option) => option.value == id));
      case "tags":
        return selectedCampaignIds.map((id) => tagOptions.find((option) => option.value == id));
      case "stage":
        return selectedCampaignIds.map((id) => stageOptions.find((option) => option.value == id));
      case "device_brand":
        return selectedCampaignIds.map((id) => deviceBrands.find((option) => option.value === id));
      case "device_os":
        return selectedCampaignIds.map((id) => deviceOSes.find((option) => option.value === id));
      case "device_type":
        return selectedCampaignIds.map((id) => deviceTypes.find((option) => option.value === id));
      case "globalcategory":
        return selectedCampaignIds.map((id) => globalCategory?.find((option) => option.value == id));
      default:
        return null;
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     debouncedHandleOnChange(data);
  //   }
  // }, [data, debouncedHandleOnChange]);

  useEffect(() => {
    if (updatedValue?.value === 1 || updatedValue?.value === 0) {
      handleOnChange(updatedValue.value);
    }
  }, []); // Empty dependency array ensures it runs only once on mount

  switch (field.type) {
    case "country":
    case "locale":
    case "region":
    case "boolean":
    case "select":
    case "multiselect":
      return (
        <div style={{ width: "30%" }}>
          <Select
            isLoading={props.loading}
            margin={true}
            isDisabled={isDisabled}
            options={getOptions(field)}
            placeholder={isDisabled ? t("caymland.queue.config.protocol.disabled") : `${t("caymland.core.select")}...`}
            value={updatedValue}
            onChange={(e) => {
              if (isDisabled) {
                handleOnChange("");
              } else if (field.type === "multiselect" || isMulti) {
                // For multi-select, `e` is an array of options
                const selectedIds = e ? e.map((option) => option.value) : [];
                handleOnChange(selectedIds);
              } else {
                // For single select, `e` is a single option object
                const selectedValue = e ? e.value : "";
                handleOnChange(selectedValue);
              }
            }}
            isMulti={field.type === "multiselect" || isMulti}
            theme={props.selectTheme}
            styles={props.selectStyles}
            menuPlacement="auto"
          />
        </div>
      );

    case "date":
    case "datetime":
    case "date":
    case "datetime":
      const debounceRef = useRef(null); // ✅ Use ref to track timeout

      const handleDateChange = (input) => {
        // ✅ Always update input field immediately
        setData(input);

        let momentDate = Moment(input, "DD.MM.YYYY HH:mm", true);
        if (!momentDate.isValid()) {
          momentDate = Moment(input, "DD.MM.YYYY", true);
        }

        if (momentDate.isValid()) {
          // ✅ Immediately update state for valid dates
          const formattedDate =
            field.type === "datetime" && field?.field !== "transaction_item_start_date"
              ? momentDate.format("YYYY-MM-DD HH:mm")
              : momentDate.format("YYYY-MM-DD");

          setData(formattedDate);
          handleOnChange(formattedDate);
        } else {
          // ✅ Debounce invalid input updates
          if (debounceRef.current) {
            clearTimeout(debounceRef.current);
          }

          debounceRef.current = setTimeout(() => {
            setData(input);
            handleOnChange(input);
          }, 1500); // Adjust debounce delay as needed
        }
      };

      return (
        <div style={{ alignSelf: "start", width: "30%", marginTop: "20px" }}>
          <DataSelect
            marginBottom={false}
            isLoading={props.loading}
            onChange={handleDateChange}
            timeFormat="HH:mm"
            value={props.value}
            showTimeSelect={field?.type === "datetime" && field?.field !== "transaction_item_start_date"}
            datestring
            disabled={props.operator === "!empty" || props.operator === "empty" || props.isDisabled}
            placeholderText={
              props.operator === "!empty" || props.operator === "empty"
                ? t("caymland.queue.config.protocol.disabled")
                : `${t("caymland.core.select")}...`
            }
          />
        </div>
      );

    case "campaign":
    case "leadlist":
    case "lead_email_received":
    case "tags":
    case "stage":
    case "device_brand":
    case "device_os":
    case "device_type":
    case "globalcategory":
      return (
        <div style={{ width: "30%" }}>
          <Select
            isLoading={props.loading}
            margin={true}
            isDisabled={isDisabled}
            isClearable={false}
            options={getOptions(field)}
            placeholder={isDisabled ? t("caymland.queue.config.protocol.disabled") : `${t("caymland.core.select")}...`}
            value={getSelectValue()}
            onChange={(e) => {
              const selectedIds = e ? e.map((option) => option.value) : [];
              setSelectedCampaignIds(selectedIds);
              const valueString = selectedIds.join(",");
              handleOnChange(selectedIds);
            }}
            isMulti={true}
            theme={props.selectTheme}
            styles={props.selectStyles}
            menuPlacement="auto"
          />
        </div>
      );
    default:
      return (
        <div style={{ width: "30%" }}>
          <Text
            name="website"
            value={localValue}
            marginBottom={true}
            onChange={(e) => {
              const selectedValue = props?.operator === "!empty" || props?.operator === "empty" ? "" : e?.target?.value;
              setLocalValue(selectedValue);
            }}
            onBlur={() => handleOnChange(localValue)}
            placeholder={
              props.operator === "!empty" ||
              props.operator === "empty" ||
              props.isDisabled ||
              props.operator === "not_empty"
                ? t("caymland.queue.config.protocol.disabled")
                : ""
            }
            disabled={
              props.operator === "!empty" ||
              props.operator === "empty" ||
              props.isDisabled ||
              props.operator === "not_empty"
            }
          />
        </div>
      );
  }
};

export default ValueEditorReusuble;
