import { Controller } from "react-hook-form";
import { Checkbox, Select, Text, TimePicker } from "../../../../reusableComponents/Inputs";
import { useState, useEffect } from "react";
import { t } from "i18next";
export default function IntervalMode({ control, watch, ...props }) {
  const [triggerState, setTriggerState] = useState({});
  useEffect(() => {
    setTriggerState(control._formValues);
  }, [control]);

  const isValidHour = (value) => value !== "NaN:NaN" && value !== undefined && value !== null && value !== "";

  const scheduleAtValue = triggerState?.triggerHour;
  const isScheduleAt = isValidHour(scheduleAtValue);

  const triggerRestrictedStartHourValue = triggerState?.triggerRestrictedStartHour;
  const triggerRestrictedStopHourValue = triggerState?.triggerRestrictedStopHour;
  const isDisableScheduleAt =
    isValidHour(triggerRestrictedStartHourValue) || isValidHour(triggerRestrictedStopHourValue);

  const showIntervalSettings = !(
    triggerState?.triggerIntervalUnit === "i" || triggerState?.triggerIntervalUnit === "h"
  );

  return (
    <div>
      <div className="d-flex justify-between mt-10px" style={{ borderBottom: "1px solid #b1b1b1" }}>
        <div className="mb-20px relative" style={{ width: "30%", height: "38px" }}>
          <Controller
            control={control}
            name={`triggerInterval`}
            render={({ field: { name, onChange, value } }) => (
              <Text
                label=""
                name={name}
                value={value}
                required
                errors={value == undefined || value == "" || value == null}
                onChange={(event) => {
                  onChange(event);
                  setTriggerState((prev) => ({
                    ...prev,
                    triggerInterval: event.target.value ?? "",
                  }));
                }}
              />
            )}
          />
        </div>
        <div className="relative" style={{ width: "60%", height: "40px" }}>
          <Controller
            control={control}
            name={`triggerIntervalUnit`}
            render={({ field: { name, onChange, value } }) => {
              console.log(value);
              return (
                <Select
                  name={name}
                  required
                  label=""
                  errors={value == undefined || value == "" || value == null}
                  options={props?.fields?.formDefaultFields?.triggerIntervalUnit?.choices}
                  placeholder={`${t("caymland.core.type.select")}...`}
                  value={props?.fields?.formDefaultFields?.triggerIntervalUnit?.choices.find(
                    (option) => option.value === value
                  )}
                  onChange={(option) => {
                    onChange(option ? option.value : "");
                    setTriggerState((prev) => ({
                      ...prev,
                      triggerIntervalUnit: option ? option.value : "",
                    }));
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      {showIntervalSettings && (
        <>
          <div
            className="interval_settings d-flex items-center"
            style={{ margin: "20px 0", width: "88%", justifyContent: "space-between" }}
          >
            <span className="intervalModeSpan">{t("caymland.campaign.form.type.interval_schedule_at")}</span>
            <Controller
              control={control}
              name={`triggerHour`}
              render={({ field: { name, onChange, value } }) => (
                <TimePicker
                  label=""
                  name={name}
                  value={value}
                  onChange={(event) => {
                    onChange(event ?? "");
                    setTriggerState((prev) => ({
                      ...prev,
                      triggerHour: event ?? "",
                    }));
                  }}
                  width="112px !important"
                  className="h-auto"
                  disabled={isDisableScheduleAt}
                  marginBottom={false}
                />
              )}
            />
            <span className="intervalModeSpan">{t("caymland.campaign.form.type.interval_schedule_between_hours")}</span>
            <Controller
              control={control}
              name={`triggerRestrictedStartHour`}
              render={({ field: { name, onChange, value } }) => (
                <TimePicker
                  label=""
                  name={name}
                  value={value}
                  onChange={(event) => {
                    onChange(event);
                    setTriggerState((prev) => ({
                      ...prev,
                      triggerRestrictedStartHour: event ?? "",
                    }));
                  }}
                  style={{ width: "40%", fontSize: "13px" }}
                  disabled={isScheduleAt}
                  marginBottom={false}
                />
              )}
            />
            <span className="intervalModeSpan">{t("caymland.core.and")}</span>
            <Controller
              control={control}
              name={`triggerRestrictedStopHour`}
              render={({ field: { name, onChange, value } }) => (
                <TimePicker
                  label=""
                  name={name}
                  value={value}
                  onChange={(event) => {
                    onChange(event);
                    setTriggerState((prev) => ({
                      ...prev,
                      triggerRestrictedStopHour: event ?? "",
                    }));
                  }}
                  style={{ width: "40%", fontSize: "13px" }}
                  disabled={isScheduleAt}
                  marginBottom={false}
                />
              )}
            />
          </div>
          <div style={{ borderTop: "1px solid #b1b1b1", paddingTop: "10px" }}>
            <span style={{ padding: 4, fontSize: "12px", fontWeight: "600" }}>
              {t("caymland.campaign.form.type.interval_trigger_restricted_dow")}
            </span>
            <div className="row mt-10px">
              {props?.fields?.formDefaultFields?.triggerRestrictedDaysOfWeek?.choices.map((e, index) => (
                <div className="col-md-3" key={index}>
                  <label style={{ padding: "4px 0" }} htmlFor={`triggerRestrictedDaysOfWeek[${index}]`}>
                    {e?.label}
                  </label>
                  <Controller
                    control={control}
                    name={`triggerRestrictedDaysOfWeek`}
                    defaultValue={[]}
                    render={({ field: { name, onChange, value = [] } }) => (
                      <Checkbox
                        label=""
                        name={`${name}[${index}]`}
                        value={value.includes(e?.value)}
                        onChange={(event) => {
                          let currentValues = [...value];
                          if (event.target.checked) {
                            let updatedValues = currentValues.includes(e?.value)
                              ? currentValues
                              : [...currentValues, e?.value];
                            if (e?.value === "-1" || updatedValues.includes("-1")) {
                              updatedValues = [...new Set([...updatedValues, "1", "2", "3", "4", "5"])];
                            }
                            onChange(updatedValues);
                          } else {
                            const indexToRemove = currentValues.indexOf(e?.value);
                            if (indexToRemove > -1) {
                              currentValues.splice(indexToRemove, 1);
                            }
                            onChange(currentValues);
                          }
                        }}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
