import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./index.scss";
import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import companyData from "./assets/companies.json";
import { useTheme } from "@mui/material/styles";

// ** Import custom components for redux **
import { Provider } from "react-redux";
import App from "./components/app";

//config data
import { store } from "./redux/store";
import { Buffer } from "buffer";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const Root = () => {
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    const currentDomain = window.location.origin;
    const matchedCompany = companyData.companies.find((company) => currentDomain.includes(company.domain));

    if (matchedCompany) {
      setCompanies(matchedCompany);
      updateCSSVariables(matchedCompany.theme.colors);

      /// test test
      updateDocumentTitleAndFavicon(
        matchedCompany.name,
        matchedCompany.logo.smallLogoPath,
        matchedCompany?.description,
        matchedCompany?.logo?.ogImage
      );
    } else {
      const defaultCompany = companyData.companies[0];
      setCompanies(defaultCompany);
      updateCSSVariables(defaultCompany.theme.colors);

      /// test test
      updateDocumentTitleAndFavicon(
        defaultCompany.name,
        defaultCompany.logo.smallLogoPath,
        defaultCompany?.description,
        defaultCompany?.logo?.ogImage
      );
    }
  }, []);

  const updateCSSVariables = (colors) => {
    const root = document.documentElement;
    root.style.setProperty("--primaryColor", colors.primaryColor || "#135f95");
    root.style.setProperty("--secondaryColor", colors.secondaryColor || "#2a3142");
    root.style.setProperty("--textPrimary", colors.textPrimary || "#333333");
    root.style.setProperty("--textSecondary", colors.textSecondary || "#2a3142");
    root.style.setProperty("--lightPrimaryColor", colors.lightPrimaryColor || "#347AA6");
    root.style.setProperty("--activeMenuBgColor", colors.activeMenuBgColor || "#47008f1a");
  };

  const updateDocumentTitleAndFavicon = (title, faviconPath) => {
    const localTitleLogo = require(`./assets/logo/${faviconPath}`);
    document.title = title;
    const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = localTitleLogo;
    document.getElementsByTagName("head")[0].appendChild(link);
  };
  // const updateDocumentMetaTags = (description, ogImage) => {
  //   updateMetaTag("property", "og:description", description);
  //   updateMetaTag("property", "og:image", ogImage);
  // };

  // // Utility function to create or update meta tags dynamically
  // const updateMetaTag = (attr, name, content) => {
  //   let metaTag = document.querySelector(`meta[${attr}='${name}']`);
  //   if (!metaTag) {
  //     metaTag = document.createElement("meta");
  //     metaTag.setAttribute(attr, name);
  //     document.head.appendChild(metaTag);
  //   }
  //   metaTag.setAttribute("content", content);
  // };

  // // Inside useEffect
  // useEffect(() => {
  //   const currentDomain = window.location.origin;
  //   const matchedCompany = companyData.companies.find((company) => currentDomain.includes(company.domain));

  //   if (matchedCompany) {
  //     setCompanies(matchedCompany);
  //     updateDocumentMetaTags(matchedCompany.description, matchedCompany.logo.ogImage);
  //   } else {
  //     const defaultCompany = companyData.companies[0];
  //     setCompanies(defaultCompany);
  //     updateDocumentMetaTags(defaultCompany.description, defaultCompany.logo.ogImage);
  //   }
  // }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: companies?.theme?.colors?.primaryColor || "#135f95",
        lightPrimaryColor: companies?.theme?.colors?.lightPrimaryColor || "#347AA6",
        activeMenuBgColor: companies?.theme?.colors?.activeMenuBgColor || "#e6effc",
        bgLogoColor: companies?.logo?.bgLogoColor || "transparent",
      },
      secondary: {
        main: companies?.theme?.colors?.secondaryColor || "#2a3142",
      },
      text: {
        primary: companies?.theme?.colors?.textPrimary || "#333333",
        secondary: companies?.theme?.colors?.textSecondary || "#2a3142",
        tertiary: companies?.theme?.colors?.textTertiary || "#ffffff",
      },
    },
    typography: {
      fontFamily: ["Titillium Web", "sans-serif"].join(","),
      allVariants: {
        color: "#313131",
      },
    },
  });

  if (process.env.REACT_APP_NODE_ENV !== "development") {
    console.log = () => {};
    console.error = () => {};
    console.info = () => {};
    console.debug = () => {};
  }

  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
