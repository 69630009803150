import { useTranslation } from "react-i18next";

const DateAdded = (props) => {
  const { t } = useTranslation();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    // Reset time for accurate date comparison
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date >= today) {
      // Today
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      return `${t("caymland.core.date.today", { time: formattedTime })}`;
    } else if (date >= yesterday) {
      // Yesterday
      const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      return `${t("caymland.lead.list.yesterday")}, ${formattedTime}`;
    } else {
      // More than a day ago
      const diffDays = Math.floor((today - date) / (1000 * 60 * 60 * 24));
      return ` ${t("caymland.core.time.days_numbered", { days: diffDays })}`;
    }
  };

  return (
    <span
      style={{
        display: "flex",
        color: "black",
        alignItems: "center",
        justifyContent: "start",
        width: "100%",
        paddingLeft: "2.5em",
      }}
    >
      {formatDate(props?.dateAdded)}
    </span>
  );
};

export default DateAdded;
