import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "./index";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Moment from "moment";

const CustomDatePicker = ({
  name,
  value,
  onChange,
  label,
  tooltip,
  required = false,
  minDate,
  maxDate,
  showTimeSelect = false,
  isClearable = true,
  disabled = false,
  placeholderText,
  myBorder,
  noBorderRadius,
  marginBottom = true,
  viewEdit = false,
  extraStyle,
  errors,
  datestring,
  timeFormat = "HH:mm",
  isLoading = false,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const refDatePicker = useRef(null);
  const debounceRef = useRef(null);
  const dateFormat = showTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy";

  // ✅ Ensure correct format when setting input value
  useEffect(() => {
    if (value) {
      const parsedDate = Moment(value, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"], true);
      if (parsedDate.isValid()) {
        const formattedDate = showTimeSelect ? parsedDate.format("DD.MM.YYYY HH:mm") : parsedDate.format("DD.MM.YYYY");

        if (formattedDate !== inputValue) {
          setInputValue(formattedDate);
        }
      } else {
        setInputValue(inputValue);
      }
    }
  }, [value]);

  // ✅ Handles Date Selection from DatePicker
  const handleInputChange = (event) => {
    const input = event.target.value;

    setInputValue(input); // ✅ Always update input field
    onChange(input); // ✅ Pass raw input directly
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const formattedDate = showTimeSelect
        ? Moment(date).format("YYYY-MM-DD HH:mm:ss")
        : Moment(date).format("YYYY-MM-DD");

      setInputValue(formattedDate);
      onChange(formattedDate);
    } else {
      setInputValue(""); // ✅ Allow clearing
      onChange("");
    }
  };

  // ✅ Handles Calendar Click
  const handleCalendarClick = () => {
    if (refDatePicker.current) {
      refDatePicker.current.setOpen(true);
    }
  };

  // ✅ Ensuring correct date format before passing to DatePicker
  const formattedValue = value
    ? Moment(value, Moment.ISO_8601, true).isValid() // ✅ Handle ISO format
      ? Moment(value).toDate() // Convert to JS Date
      : showTimeSelect
      ? Moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid()
        ? Moment(value, "YYYY-MM-DD HH:mm:ss", true).toDate()
        : null
      : Moment(value, "YYYY-MM-DD", true).isValid()
      ? Moment(value, "YYYY-MM-DD", true).toDate()
      : null
    : null;

  return datestring ? (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />}
      <div style={{ position: "relative" }}>
        <input
          type="text"
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholderText}
          disabled={disabled}
          className={`form-control datepicker-placeholder hover-effect ${
            viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
          } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
          style={{ paddingLeft: "40px" }}
        />
        <CalendarTodayIcon
          onClick={handleCalendarClick}
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "gray",
            fontSize: "22px",
            cursor: "pointer",
          }}
        />
      </div>
      <DatePicker
        ref={refDatePicker}
        selected={formattedValue}
        onChange={handleDateChange}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        timeFormat={timeFormat}
        customInput={<React.Fragment />}
        disabled={disabled}
        required={required}
        placeholderText={placeholderText}
      />
    </div>
  ) : (
    <div className={`w-full ${marginBottom ? "mb-20px" : ""}`}>
      {label && <Label name={name} label={label} tooltip={tooltip} required={required} errors={errors} />}
      <DatePicker
        name={name}
        placeholderText={placeholderText}
        disabled={disabled}
        className={`form-control datepicker-placeholder hover-effect ${
          viewEdit ? "h-32 inherit font-13 noBorders" : "h-40 white font-15"
        } ${myBorder ? myBorder : ""} ${noBorderRadius ? "noBorderRadius" : "borderRadius"} ${extraStyle?.className}`}
        dateFormat={dateFormat}
        showYearDropdown
        showTimeSelect={showTimeSelect}
        showMonthDropdown
        timeFormat={timeFormat}
        isClearable={isClearable}
        isLoading={isLoading}
        required={required}
        selected={formattedValue}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default CustomDatePicker;
