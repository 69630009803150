import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Select } from "../../../../reusableComponents/Inputs";
import Button from "../../../../reusableComponents/Buttons/Button";
import Loading from "../../../../reusableComponents/loading";
import { useGetSmsesQuery } from "../../../../../redux/api/smsApi";
import { t } from "i18next";
import ClipLoader from "react-spinners/ClipLoader";

export default function StaticSmsFields({ control, errors, watch }) {
  const { data: smsOptions, refetch, isFetching } = useGetSmsesQuery({});
  const [selectedSms, setSelectedSms] = useState(null);

  const groupedOptions =
    smsOptions?.data
      ?.filter((sms) => sms.smsType === "template")
      ?.reduce((acc, sms) => {
        const languageLabel = sms.language || "Unknown";
        if (!acc[languageLabel]) {
          acc[languageLabel] = [];
        }
        acc[languageLabel].push({
          label: sms.name,
          value: sms.id,
        });
        return acc;
      }, {}) || {};

  const selectOptions = Object.entries(groupedOptions).map(([language, options]) => ({
    label: language,
    options,
  }));

  // Setup initial value if editing
  useEffect(() => {
    const smsValue = watch("properties.sms"); // Get value from form state
    if (smsValue) {
      const foundOption = selectOptions.flatMap((option) => option.options).find((opt) => opt.value === smsValue);
      if (foundOption) {
        setSelectedSms(foundOption); // Set selected SMS based on the watch value
      }
    }
  }, [selectOptions, watch]);

  return (
    <div className="tw-mt-4">
      {isFetching ? (
        <Loading />
      ) : (
        <Controller
          control={control}
          name="properties.sms"
          render={({ field: { name, value, onChange } }) => (
            <Select
              name={name}
              value={selectOptions.flatMap((option) => option.options).find((opt) => opt.value === value)}
              label="Select SMS Option"
              options={selectOptions}
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
                setSelectedSms(selectedOption);
              }}
              errors={errors?.properties?.[name]}
            />
          )}
        />
      )}

      <div style={{ marginTop: 10, display: "flex", gap: 10 }}>
        <Button
          buttons={[
            {
              name: "Print1",
              title: t("caymland.sms.send.new.sms"),
              onClick: () => window.open("/sms/new", "_blank"),
              float: "left",
              margin: "0 10px 0 0",
            },
          ]}
        />
        <Button
          buttons={[
            {
              name: "Print2",
              title: t("caymland.sms.send.edit.sms"),
              onClick: () => selectedSms && window.open(`/sms/edit/${selectedSms?.value}`, "_blank"),
              float: "left",
              margin: "0 10px 0 0",
              not_active: !selectedSms,
            },
          ]}
        />
      </div>
    </div>
  );
}
