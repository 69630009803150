import React, { useEffect, useState } from "react";
import { t } from "i18next";
import "animate.css";
import { Archive, Forward, Reply, ReplyAll, Inbox } from "lucide-react";
import { format } from "date-fns";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Button } from "../../ui/button";
import { Separator } from "../../ui/separator";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../ui/tooltip";
import { ScrollArea, ScrollBar } from "../../ui/scroll-area";
import InnerHTML from "dangerously-set-html-content";
import {
  useArchiveMessageMutation,
  useDeleteMessageMutation,
  useDownloadMessageMutation,
  useEditMessageMutation,
  useLockMessageMutation,
  useMarkAsReadMutation,
  useMarkAsUnreadMutation,
  useUnlockMessageMutation,
} from "../../../redux/api/mailboxApi";
import { classificationApi } from "../../../redux/api";
import { Select, Label } from "../../reusableComponents/Inputs";
import { Edit } from "@mui/icons-material";
import {
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  MarkEmailUnreadOutlined as MarkEmailUnreadOutlinedIcon,
  MarkEmailReadOutlined as MarkEmailReadOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  DocumentScannerOutlined as DocumentScannerOutlinedIcon,
} from "@mui/icons-material";
import { ResizablePanel } from "../../../components/ui/resizable";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller } from "react-hook-form";
import { useGetClassificationsQuery } from "../../../redux/api/classificationApi";
import { useNavigate } from "react-router-dom";
import { SlideAlert } from "../../reusableComponents/Alerts";
import { useTheme } from "@mui/material/styles";
import Moment from "moment";

function removeStyleTags(html) {
  return html.replace(/<style[\s\S]*?>[\s\S]*?<\/style>/gi, "");
}

export function MailDisplay({
  mail,
  activeMail,
  setParsedEmail,
  addTab,
  captureTextSelection,
  setLockStatus,
  lockStatus,
  isMobile,
  setView,
}) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");
  const { palette } = useTheme();

  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: 30,
    orderByDir: "DESC",
    orderBy: "id",
    where: [
      // {
      //   expr: "eq",
      //   col: "type",
      //   val: "outgoing",
      // },
    ],
  });

  const [markAsRead] = useMarkAsReadMutation();
  const [markAsUnread] = useMarkAsUnreadMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [lockMessage] = useLockMessageMutation();
  const [unlockMessage] = useUnlockMessageMutation();
  const [archiveMessage] = useArchiveMessageMutation();
  const [editMessage] = useEditMessageMutation();
  const [downloadMessage] = useDownloadMessageMutation();

  const { data, isFetching, refetch } = useGetClassificationsQuery(query);
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    resolver: yupResolver(yup.object({})),
  });

  useEffect(() => {
    if (data && activeMail && activeMail.classification) {
      const matchedClassification = data.data.find((c) => c.alias === activeMail.classification);
      if (matchedClassification) {
        reset({
          classification: matchedClassification,
        });
      }
    }
  }, [data, activeMail, reset]);

  const dataClass = watch("classification");
  function bufferToUrl(buffer, type) {
    const blob = new Blob([buffer], { type });
    return URL.createObjectURL(blob);
  }

  const singleMessageHandler = async (actionType, activeMail) => {
    try {
      let response;
      if (actionType === "read") {
        response = await markAsRead({ ids: [activeMail.id] });
      } else if (actionType === "unread") {
        response = await markAsUnread({ ids: [activeMail.id] });
      } else if (actionType === "delete") {
        response = await deleteMessage({ ids: [activeMail.id] });
        setParsedEmail(null);
      } else if (actionType === "lock") {
        response = await lockMessage({ ids: [activeMail.id] });
        setLockStatus(true);
      } else if (actionType === "unlock") {
        response = await unlockMessage({ ids: [activeMail.id] });
        setLockStatus(false);
      } else if (actionType === "archive") {
        response = await archiveMessage({ ids: [activeMail.id] });
        setParsedEmail(null);
      }

      if (response && response.data) {
        setShow(true);
        setMessage(response.data.message);
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 5000);
      } else {
        console.error("Failed to mark email:", response.error);
      }
    } catch (error) {
      console.error("Error in marking email:", error);
    }
  };

  function createEmlContent(mail) {
    let emlContent = "";

    mail.headers.forEach((header) => {
      emlContent += `${header.name}: ${header.value}\r\n`;
    });

    emlContent += `Subject: ${mail.subject}\r\n`;
    emlContent += `From: ${mail.from.address}\r\n`;
    emlContent += `To: ${mail.to.map((to) => to.address).join(", ")}\r\n`;
    emlContent += "\r\n";

    if (mail.text) {
      emlContent += mail.text + "\r\n\r\n";
    }
    if (mail.html) {
      emlContent += mail.html + "\r\n\r\n";
    }

    return emlContent;
  }

  const downloadEmlFile = async () => {
    try {
      const response = await downloadMessage(activeMail.hash).unwrap();

      const blob = new Blob([response], { type: "message/rfc822" });

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", activeMail.hash);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Failed to download message", error);
    }
  };

  const handleMailAction = (actionType) => {
    let replyData = {
      subject: "",
      body: mail?.html,
    };

    switch (actionType) {
      case "Reply":
        replyData.subject = `Re: ${mail?.subject}`;
        replyData.to = mail?.from?.address;
        break;
      case "Reply All":
        replyData.subject = `Re: ${mail?.subject}`;
        replyData.to = mail?.from?.address;
        replyData.cc = mail?.cc?.map((person) => person.address).join("; ") ?? "";
        break;
      case "Forward":
        replyData.subject = `Fw: ${mail?.subject}`;
        delete replyData.to;
        break;
      default:
        return;
    }

    addTab(actionType, replyData);
  };

  const handleSaveClassification = async (formData) => {
    if (!activeMail) {
      return;
    }
    await editMessage({ id: activeMail?.id, classification: formData.classification.alias });
  };

  const handleBrochure = () => {
    if (activeMail.lead !== null && typeof activeMail.lead === "object") {
      const brochurePath = `/orders/new?id=${activeMail?.lead?.id}`;

      localStorage.setItem("brochureInitialData", JSON.stringify(activeMail?.textPlain));
      window.open(`${window.location.origin}${brochurePath}`, "_blank");
    }
  };

  function modifyLinks(html) {
    if (!html) return "";

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    doc.querySelectorAll("a").forEach((anchor) => {
      anchor.setAttribute("target", "_blank");
      anchor.setAttribute("rel", "noopener noreferrer");
    });

    return doc.body.innerHTML;
  }

  const content = (
    <>
      <ResizablePanel defaultSize={65} minSize={50} className="tw-min-h-[90vh]">
        <SlideAlert background={backgorundToast} show={show} message={message} setShowAlert={setShow} />
        <div className="tw-flex tw-h-full  tw-flex-col">
          <div className="tw-flex tw-items-center tw-flex-col md:tw-flex-row tw-p-2 ">
            <div className="tw-flex tw-items-center tw-gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled={!mail}
                    onClick={(e) => singleMessageHandler("archive", activeMail)}
                  >
                    <Archive className="tw-h-5 tw-w-5" />
                    <span className="sr-only">{t("caymland.mailbox.list.action.archiveWithoutAnswer")}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t("caymland.mailbox.list.action.archiveWithoutAnswer")}</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled={!mail}
                    onClick={(e) => singleMessageHandler("unread", activeMail)}
                  >
                    <MarkEmailUnreadOutlinedIcon style={{ fontSize: "22px" }} />
                    <span className="sr-only">{t("caymland.mailbox.list.action.setAsUnread")}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t("caymland.mailbox.list.action.setAsUnread")}</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled={!mail}
                    onClick={(e) => singleMessageHandler("read", activeMail)}
                  >
                    <MarkEmailReadOutlinedIcon style={{ fontSize: "22px" }} />
                    <span className="sr-only">{t("caymland.mailbox.list.action.setAsRead")}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t("caymland.mailbox.list.action.setAsRead")}</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    disabled={!mail}
                    onClick={(e) => singleMessageHandler("delete", activeMail)}
                  >
                    <DeleteOutlineOutlinedIcon style={{ fontSize: "22px" }} />
                    <span className="sr-only">{t("caymland.mailbox.list.action.deleteMessage")}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t("caymland.mailbox.list.action.deleteMessage")}</TooltipContent>
              </Tooltip>

              {!isMobile && lockStatus === false && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      disabled={!mail}
                      onClick={(e) => singleMessageHandler("lock", activeMail)}
                    >
                      <LockOutlinedIcon style={{ fontSize: "22px" }} />
                      <span className="sr-only">{t("caymland.mailbox.list.action.lockMessage")}</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t("caymland.mailbox.list.action.lockMessage")}</TooltipContent>
                </Tooltip>
              )}
              {!isMobile && lockStatus === true && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      disabled={!mail}
                      onClick={(e) => singleMessageHandler("unlock", activeMail)}
                    >
                      <LockOpenOutlinedIcon style={{ fontSize: "22px" }} />
                      <span className="sr-only">{t("caymland.mailbox.list.action.unlock")}</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t("caymland.mailbox.list.action.unlock")}</TooltipContent>
                </Tooltip>
              )}
              {!isMobile && (
                <>
                  {" "}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={!mail} onClick={downloadEmlFile}>
                        <FileDownloadOutlinedIcon style={{ fontSize: "22px" }} />
                        <span className="sr-only">{t("caymland.mailbox.list.action.download")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.download")}</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={!mail} onClick={() => handleBrochure()}>
                        <DocumentScannerOutlinedIcon style={{ fontSize: "22px" }} />
                        <span className="sr-only">{t("caymland.mailbox.list.action.brochure")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.brochure")}</TooltipContent>
                  </Tooltip>{" "}
                </>
              )}
              {isMobile && (
                <>
                  {" "}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={!mail} onClick={() => handleMailAction("Reply")}>
                        <Reply className="tw-h-5 tw-w-5" />
                        <span className="sr-only">{t("caymland.mailbox.list.action.reply")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.reply")}</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        disabled={!mail}
                        onClick={() => handleMailAction("Reply All")}
                      >
                        <ReplyAll className="tw-h-5 tw-w-5" />
                        <span className="sr-only">{t("caymland.mailbox.list.action.replyAll")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.replyAll")}</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={!mail} onClick={() => handleMailAction("Forward")}>
                        <Forward className="tw-h-5 tw-w-5" />
                        <span className="sr-only">{t("caymland.mailbox.list.action.forward")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.forward")}</TooltipContent>
                  </Tooltip>
                </>
              )}
            </div>
            <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
              {isMobile && (
                <Button
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "30px",
                    backgroundColor: palette.primary.main,
                    zIndex: 9999,
                    pointerEvents: "auto", // Ensure it's clickable
                  }}
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault();
                    setView("inbox");
                  }}
                >
                  <Inbox style={{ color: "white" }} size={16} />
                </Button>
              )}

              {!isMobile && (
                <>
                  {" "}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={!mail} onClick={() => handleMailAction("Reply")}>
                        <Reply className="tw-h-5 tw-w-5" />
                        <span className="sr-only">{t("caymland.mailbox.list.action.reply")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.reply")}</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        disabled={!mail}
                        onClick={() => handleMailAction("Reply All")}
                      >
                        <ReplyAll className="tw-h-5 tw-w-5" />
                        <span className="sr-only">{t("caymland.mailbox.list.action.replyAll")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.replyAll")}</TooltipContent>
                  </Tooltip>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="icon" disabled={!mail} onClick={() => handleMailAction("Forward")}>
                        <Forward className="tw-h-5 tw-w-5" />
                        <span className="sr-only">{t("caymland.mailbox.list.action.forward")}</span>
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t("caymland.mailbox.list.action.forward")}</TooltipContent>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          <Separator />
          {mail ? (
            <div className="flex flex-1 flex-col overflow-hidden">
              <div className="flex items-start p-4 tw-flex-col md:tw-flex-row">
                <div className="flex items-start gap-4 text-[10px]">
                  <Avatar>
                    <AvatarImage alt={mail?.from?.name} />
                    <AvatarFallback className="tw-text-[10px]">
                      {mail.from?.name
                        .split(" ")
                        .filter((n) => n)
                        .map((chunk) => chunk[0].toUpperCase())
                        .join("")}
                    </AvatarFallback>
                  </Avatar>
                  <div className="grid gap-1">
                    <div className="font-semibold tw-text-[13px]">{mail?.from?.name}</div>
                    <div className="line-clamp-1 tw-text-[13px]">{mail?.subject}</div>
                    <div className="line-clamp-1 tw-text-[13px]">
                      <span className="font-medium tw-text-[13px]">Reply-To:</span> {mail?.to[0]?.address ?? ""}
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-w-full tw-justify-end">
                  <div className="ml-auto tw-text-[13px] text-muted-foreground tw-mb-2">
                    {Moment(mail.date).format("DD.MM.YYYY HH:mm")}
                  </div>
                  {mail.attachments && mail.attachments.length > 0 && (
                    <div className="tw-p-3 tw-flex tw-justify-between tw-w-full  tw-items-center  tw-flex-wrap tw-overflow-y-auto tw-border-t tw-border-[lightgrey] tw-h-[66px]">
                      {mail.attachments.map((attachment, index) => {
                        const truncatedFilename =
                          attachment?.filename?.length > 15
                            ? `${attachment?.filename?.slice(0, 15)}...`
                            : attachment?.filename;

                        return (
                          <div key={index} className="tw-max-w-[100%] tw-px-3 tw-flex tw-items-center tw-mb-2 tw-pb-1">
                            <a
                              href={bufferToUrl(attachment.content, attachment.mimeType)}
                              download={attachment.filename}
                              className="tw-text-gray tw-cursor-pointer hover:tw-underline"
                              title={attachment.filename} // Show full name on hover
                            >
                              {truncatedFilename}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* <div
                    className={`cursor-pointer tw-flex tw-justify-end tw-items-center tw-ml-auto  tw-min-h-5 tw-w-60`}
                    style={{ zoom: 0.8 }}
                  >
                    <Controller
                      control={control}
                      name="classification"
                      render={({ field: { name, onChange, value } }) => (
                        <Select
                          name={name}
                          options={data?.data || []}
                          isClearable={false}
                          margin={true}
                          labelField="name"
                          valueField="alias"
                          value={value}
                          onChange={(selectedOption) => {
                            onChange(selectedOption);
                            handleSaveClassification({ classification: selectedOption });
                          }}
                        />
                      )}
                    />
                  </div> */}
                </div>
              </div>
              <Separator />
              <ScrollArea className="tw-w-full rounded-md flex-1 p-2 overflow-auto" onMouseUp={captureTextSelection}>
                <div style={{ zoom: 0.9 }}>
                  {mail.html && <InnerHTML html={modifyLinks(removeStyleTags(mail?.html))} allowRerender />}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </div>
          ) : (
            <div className="tw-p-8 tw-text-center tw-text-muted-foreground tw-text-[12px]">No message selected</div>
          )}
        </div>
      </ResizablePanel>
    </>
  );

  return isMobile ? <div className="tw-min-h-[90vh]"> {content}</div> : content;
}
