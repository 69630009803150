import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import qs from "qs";
import Fields from "../fields/Fields";
import Loading from "../../../../reusableComponents/loading";
import { useForm } from "react-hook-form";
import { campaignsApi, useCampaignFieldsQuery } from "../../../../../redux/api/campaignsApi";
import { useGetFormQuery } from "../../../../../redux/api/formsApi";

function EventForm({ handleOnSelectEvent, control, getValues, reset, setValue, watch, params, errors, ...props }) {
  const [eventFields, setEventFields] = useState();
  const [error, setError] = useState(false);
  const [prevField, setPrevField] = useState(null);
  const [action, setAction] = useState(props.action);
  let fields = watch("fields");

  const [trigger, { isFetching: isFetching, isLoading: isLoading }] = campaignsApi.useLazyCampaignFieldsQuery();

  const getEventType = async (type, eventType, alias, operator, changed, form) => {
    let { action } = props;
    try {
      const query = {
        eventType: type,
        type: eventType,
        anchor: props?.anchors,
        anchorEventType: props.anchorEventType === "leadSource" ? "source" : params.anchorEventType,
        alias: alias,
        operator: operator,
        changed: form ? "field" : changed,
        form: form,
        field: alias,
      };

      const response = await trigger(query);

      if (!response?.error) {
        const fieldsData = response.data;
        return fieldsData;
      } else {
        throw new Error("Failed to load fields");
      }
    } catch (error) {
      setError(error.message);
      throw error;
    }
  };

  // Initial data fetch on mount
  useEffect(() => {
    let { type, eventType = params.type } = props;
    if (type && eventType && !_.has(getValues(), "fields")) {
      getEventType(type, eventType, null, null, null, null)
        .then((result) => setEventFields(result))
        .catch((e) => setError(e));
    }
  }, []);

  // Fetch data when 'field' changes
  useEffect(() => {
    const field = watch("properties")?.field;
    const operator = watch("properties")?.operator;
    const form = watch("properties")?.form;

    if (field && field !== prevField) {
      setPrevField(field);
      let { type, eventType = params.type } = props;

      if (type && eventType) {
        getEventType(type, eventType, field, operator, "field", form)
          .then((result) => {
            setValue("fields.formProperties.operator", result?.formProperties?.operator);
            setValue("fields.formProperties.value", result?.formProperties?.value);
            setValue("fields.formProperties.form", result?.formProperties?.form);
            setValue("fields.formProperties.field", result?.formProperties?.field);
          })
          .catch((e) => setError(e));
      }
    }
  }, [watch("properties")?.field, watch("properties")?.form]);

  // Fetch data when 'operator' changes
  useEffect(() => {
    const operator = watch("properties")?.operator;
    const field = watch("properties")?.field;
    const form = watch("properties")?.form;
    if (operator) {
      let { type, eventType = params.type } = props;
      if (type && eventType) {
        getEventType(type, eventType, field, operator, "operator", form, field)
          .then((result) => {
            setValue("fields.formProperties.value", result?.formProperties?.value);
            setValue("fields.formProperties.form", result?.formProperties?.form);
            setValue("fields.formProperties.field", result?.formProperties?.field);
            setAction("add");
          })
          .catch((e) => setError(e));
      }
    }
  }, [watch("properties")?.operator, watch("properties")?.form]);

  useEffect(() => {
    if (eventFields && !_.has(getValues(), "fields")) {
      let { action } = props;
      let triggerMode =
        (control?._formValues?.type === "lead.scorecontactscompanies" &&
          (control?._formValues?.anchors === "no" || control?._formValues?.decisionPath === "no")) ||
        (props?.eventType === "lead.scorecontactscompanies" && props?.anchors === "no")
          ? "interval"
          : "immediate";
      let defaultForm = {
        eventName: eventFields.campaignEventLabel,
        eventDescription: eventFields.campaignEventTooltip,
        name: eventFields.campaignEventLabel,
        triggerMode: triggerMode,
      };
      switch (action) {
        case "edit":
          reset({
            ...defaultForm,
            ...params,
            ...{ props },
            fields: eventFields,
          });
          break;
        case "add":
          const {
            parent: { params: parentParams },
            ...rest
          } = props;
          reset({
            ...defaultForm,
            parent: { ...parentParams },
            ...rest,
            fields: eventFields,
          });
          break;
      }
    }
  }, [eventFields, getValues, reset, params, props]);

  const [formFields, setFormFields] = useState([]);
  const [id, setId] = useState(null);
  const { data } = useGetFormQuery(id, { skip: !id });

  const formattedChoices = useMemo(() => {
    if (!data) return [];

    return data?.form?.fields?.map((field) => ({
      label: field.label,
      value: field.id.toString(),
    }));
  }, [data]);

  // useEffect(() => {
  //   if (_.has(fields, "formProperties")) {
  //     setFormFields(Object.values(fields.formProperties));
  //   }
  // }, [watch("properties")?.field, fields]);

  // useEffect(() => {
  //   if (control?._formValues?.type === "form.field_value" || control?._formValues?.eventType === "form.field_value")
  //     setId(control?._formValues?.properties?.form);
  // }, [control]);

  // useEffect(() => {
  //   if (_.has(fields, "formProperties")) {
  //     const emailType = watch()?.properties?.email_type;
  //     let updatedFormProperties = Object.values(fields.formProperties);
  //     const buttonGroupIndex = updatedFormProperties.findIndex((field) => field?.childType === "button_group");
  //     if (emailType === "marketing") {
  //       updatedFormProperties = updatedFormProperties.filter(
  //         (field) => field.name !== "priority" && field.name !== "attempts"
  //       );
  //       const priorityField = fields.formProperties["priority"];
  //       const attemptsField = fields.formProperties["attempts"];
  //       updatedFormProperties.splice(buttonGroupIndex + 1, 0, priorityField, attemptsField);
  //       setFormFields(Object.values(updatedFormProperties));
  //     } else {
  //       updatedFormProperties = updatedFormProperties.filter(
  //         (field) => field.name !== "priority" && field.name !== "attempts"
  //       );
  //     }
  //     setFormFields(updatedFormProperties);
  //   }
  // }, [fields, watch()?.properties?.email_type]);
  return (
    <>
      {error ? (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{error}</span>
      ) : (
        <>
          {(action == "edit" ? isFetching : !watch("fields") && isLoading) ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : (
            <Fields
              extraOptions={formattedChoices}
              setId={setId}
              formFields={formFields}
              fields={fields}
              control={control}
              watch={watch}
              errors={errors}
            />
          )}
        </>
      )}
    </>
  );
}

export default EventForm;
