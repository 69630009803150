import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { t } from "i18next";
import { TbDeviceFloppy } from "react-icons/tb";
import { ImCancelCircle } from "react-icons/im";
import { RiArrowLeftRightFill } from "react-icons/ri";
import Button from "../../../reusableComponents/Buttons/Button";
import { useGetSegmentsOptionsQuery } from "../../../../redux/api/segmentsApi";

const DisplayConditionModal = ({
  openModal,
  setOpenModal,
  rightColumnValue,
  setRightColumnValue,
  emailEditorRef,
  setApplyDCond,
  segmentOptions,
}) => {
  const [leftColumnValue, setLeftColumnValue] = useState(segmentOptions || []);
  const [leftColumnSearchValue, setLeftColumnSearchValue] = useState("");
  const [rightColumnSearchValue, setRightColumnSearchValue] = useState("");
  useEffect(() => {
    if (segmentOptions) {
      const filteredLeftColumnValue = segmentOptions?.filter(
        (option) => !rightColumnValue.some((selected) => selected.value === option.value)
      );
      setLeftColumnValue(filteredLeftColumnValue || []);
    }
  }, [segmentOptions, rightColumnValue]);

  const toggle = () => {
    setOpenModal((prev) => !prev);
  };

  const handleLeftColumnSearchChange = (e) => {
    setLeftColumnSearchValue(e.target.value);
  };

  const handleRightColumnSearchChange = (e) => {
    setRightColumnSearchValue(e.target.value);
  };

  const moveItem = (item, direction) => {
    if (direction === "right") {
      setRightColumnValue([...rightColumnValue, item]);
      setLeftColumnValue(leftColumnValue.filter((i) => i.value !== item.value));
    } else {
      setLeftColumnValue([...leftColumnValue, item]);
      setRightColumnValue(rightColumnValue.filter((i) => i.value !== item.value));
    }
  };

  const handleSelectAllColumns = () => {
    setRightColumnValue([...rightColumnValue, ...leftColumnValue]);
    setLeftColumnValue([]);
  };

  const handleDeselectAllColumns = () => {
    setLeftColumnValue([...leftColumnValue, ...rightColumnValue]);
    setRightColumnValue([]);
  };
  console.log(rightColumnValue);

  return (
    <Modal isOpen={openModal} toggle={toggle} backdrop={false}>
      <ModalHeader toggle={toggle}>{t("caymland.core.condition.label")}</ModalHeader>
      <ModalBody>
        <div className="form-row w-full">
          <div className="col-12 px-3" style={{ marginRight: "30px" }}>
            <h4 className="mt-10px required">
              <strong>{t("caymland.core.fields.export.label")}</strong>
            </h4>
            <div className="d-flex">
              <div className="w-50">
                <div className="flex flex-col gap-10 w-100">
                  <div className="input-container ic1 mb-10px">
                    <input
                      type="text"
                      value={leftColumnSearchValue}
                      onChange={handleLeftColumnSearchChange}
                      placeholder={t("caymland.core.multiselect.search")}
                      className="form-control"
                    />
                  </div>
                </div>

                <select multiple style={{ height: "200px" }} className="form-control">
                  {leftColumnValue
                    .filter((option) => option?.label?.toLowerCase()?.includes(leftColumnSearchValue.toLowerCase()))
                    .map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        style={{ fontSize: "14px" }}
                        onClick={() => moveItem(option, "right")}
                      >
                        {option.label}
                      </option>
                    ))}
                </select>

                <div className="float-right">
                  <span className="cursor-pointer" onClick={handleSelectAllColumns}>
                    {t("caymland.core.select.all")}
                  </span>
                </div>
              </div>
              <RiArrowLeftRightFill size={35} className="align-self-center" />
              <div className="w-50" style={{ marginLeft: "10px" }}>
                <div className="flex flex-col gap-10 w-100">
                  <div className="input-container ic1 mb-10px">
                    <input
                      type="text"
                      value={rightColumnSearchValue}
                      onChange={handleRightColumnSearchChange}
                      placeholder={t("caymland.core.multiselect.search")}
                      className="form-control"
                    />
                  </div>
                </div>
                <select multiple style={{ height: "200px" }} className="form-control">
                  {rightColumnValue
                    .filter((option) => option.label.toLowerCase().includes(rightColumnSearchValue.toLowerCase()))
                    .map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        style={{ fontSize: "14px" }}
                        onClick={() => moveItem(option, "left")}
                      >
                        {option.label}
                      </option>
                    ))}
                </select>

                <span className="cursor-pointer" onClick={handleDeselectAllColumns}>
                  {t("caymland.core.deselect.all")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          buttons={[
            {
              name: "Cancel",
              title: `${t("caymland.core.close")}`,
              icon: <ImCancelCircle size={15} />,
              onClick: () => toggle(),
            },
            {
              name: "Apply",
              title: `${t("caymland.core.form.apply")}`,
              icon: <TbDeviceFloppy size={15} />,
              not_active: rightColumnValue?.length <= 0, // Corrected "length"
              isDisabled: rightColumnValue?.length <= 0, // Corrected "length"
              onClick: () => {
                setApplyDCond(true);
                toggle();
              },
            },
          ]}
        />
      </ModalFooter>
    </Modal>
  );
};

export default DisplayConditionModal;
