import React, { useRef } from "react";
import { roundingMode } from "../../../reusableComponents/data";
import { FaTimes, FaCheck } from "react-icons/fa";
import { BiDotsVertical } from "react-icons/bi";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Button from "../../../reusableComponents/Buttons/Button";
import { Select, Text, Label } from "../../../reusableComponents/Inputs";
import { t } from "i18next";

const SortableItem = SortableElement(({ value, index, onChange, onRemove, rowIndex }) => {
  const inputRef = useRef(null);

  return (
    <div index={rowIndex} className="d-flex items-center" style={{ marginBottom: "5px" }}>
      <button
        type="button"
        onClick={() => onRemove(rowIndex)}
        className="dataType-options-btn button-with-x-background bgd1"
        style={{
          borderRadius: "4px 0 0 4px",
        }}
      ></button>
      <div className="input-container ic1" style={{ height: "28px" }}>
        <input
          ref={inputRef} // Attach the ref
          style={{ borderRadius: "0", fontSize: "14px", paddingTop: "0" }}
          name={`label-${value.id}`}
          id="label"
          value={value.label}
          className="jump-input"
          type="text"
          placeholder={t("caymland.core.label")}
          onChange={(e) => {
            onChange(e, rowIndex, "label");

            // Preserve focus after state update
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 0);
          }}
        />
      </div>
      <div className="input-container ic1" style={{ height: "28px" }}>
        <input
          style={{ borderRadius: "0", fontSize: "14px", paddingTop: "0" }}
          name={`value-${value.id}`}
          id="value"
          value={value.value}
          className="jump-input"
          type="text"
          placeholder={t("caymland.form.field.form.value")}
          onChange={(e) => onChange(e, rowIndex, "value")}
        />
      </div>

      <button type="button" className="dataType-options-btn" style={{ borderRadius: "0 4px 4px 0" }}>
        <span>
          <BiDotsVertical size={12} />
        </span>
      </button>
    </div>
  );
});

const SortableList = SortableContainer(({ items, onChange, onRemove }) => {
  return (
    <div>
      {items
        .sort((a, b) => a.position - b.position)
        .map((value, index) => (
          <SortableItem
            value={value}
            rowIndex={index}
            index={index}
            key={value.id}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
});

const SortableItemLookUp = SortableElement(({ value, rowIndex, onChange, onRemove }) => (
  <div index={rowIndex} className="d-flex items-center" style={{ marginBottom: "5px" }}>
    <button
      type="button"
      onClick={() => onRemove(rowIndex)}
      className="dataType-options-btn button-with-x-background bgd1"
      style={{ borderRadius: "4px 0 0 4px" }}
    ></button>
    <div class="input-container ic1" style={{ height: "28px" }}>
      <input
        style={{
          borderRadius: "0",
          fontSize: "14px",
          paddingTop: "0",
        }}
        name={`value-${value.id}`}
        id="value"
        value={value?.value}
        class="jump-input"
        type="text"
        placeholder={t("caymland.form.field.form.value")}
        onChange={(e) => onChange(e, rowIndex)}
      />
    </div>
    <button type="button" className="dataType-options-btn" style={{ borderRadius: "0 4px 4px 0" }}>
      <span>
        <BiDotsVertical size={12} />
      </span>
    </button>
  </div>
));

const SortableListLookUp = SortableContainer(({ items, onChange, onRemove }) => {
  return (
    <div>
      {items
        .sort((a, b) => a.position - b.position)
        .map((value, index) => (
          <SortableItemLookUp
            value={value}
            rowIndex={index}
            index={index}
            key={value.id}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
});

const HelperComponent = ({
  modifiedData,
  setModifiedData,
  handleOptionsAdd,
  optionsList,
  onSortEnd,
  handleOptionsChange,
  handleOptionsRemove,
  lookUpList,
  noValue,
  setNoValue,
  yesValue,
  setYesValue,
}) => {
  switch (modifiedData.type) {
    case "multiselect":
    case "select":
      return (
        <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
          <Label name={"Options"} label={t("caymland.lead.field.form.properties.select")} required={true} />

          <button type="button" onClick={() => handleOptionsAdd()} className="addOptions-btn">
            <span>{t("caymland.form.button.add.option")}</span>
          </button>
          <SortableList
            items={optionsList}
            onSortEnd={onSortEnd}
            onChange={handleOptionsChange}
            onRemove={handleOptionsRemove}
            axis={"y"}
          />
        </div>
      );
    case "lookup":
      return (
        <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
          <Label name={"Options"} label={t("caymland.lead.field.form.properties.select")} required={true} />
          <button type="button" onClick={() => handleOptionsAdd()} className="addOptions-btn">
            <span>{t("caymland.form.button.add.value")}</span>
          </button>
          <SortableListLookUp
            items={lookUpList}
            onSortEnd={onSortEnd}
            onChange={handleOptionsChange}
            onRemove={handleOptionsRemove}
            axis={"y"}
          />
        </div>
      );
    case "boolean":
      return (
        <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
          <Label name={"Boolean Labels"} label={t("caymland.lead.field.form.properties.boolean")} />
          <div className="flex items-center gap-10">
            <div className="input-group">
              <div className="input-group-prepend" style={{ height: "40px" }}>
                <Button
                  buttons={[
                    {
                      name: "FaTimes",
                      icon: <FaTimes size={12} />,
                    },
                  ]}
                />
                <Text
                  name={"NoValue"}
                  value={noValue}
                  onChange={(e) => {
                    const newNoValue = e.target.value;
                    setNoValue(newNoValue);
                    setModifiedData((prevData) => ({
                      ...prevData,
                      properties: {
                        no: newNoValue,
                        yes: yesValue,
                      },
                    }));
                  }}
                  onBlur={() => {
                    if (noValue === "") {
                      const defaultNoValue = t("caymland.core.form.no");
                      setNoValue(defaultNoValue);
                      setModifiedData((prevData) => ({
                        ...prevData,
                        properties: {
                          no: defaultNoValue,
                          yes: yesValue,
                        },
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <div className="input-group" style={{ flexWrap: "nowrap" }}>
              <div className="input-group-prepend" style={{ height: "40px" }}>
                <Button
                  buttons={[
                    {
                      name: "FaCheck",
                      icon: <FaCheck size={12} />,
                    },
                  ]}
                />
                <Text
                  name={"YesValue"}
                  value={yesValue}
                  onChange={(e) => {
                    const newYesValue = e.target.value;
                    setYesValue(newYesValue);
                    setModifiedData((prevData) => ({
                      ...prevData,
                      properties: {
                        no: noValue,
                        yes: newYesValue,
                      },
                    }));
                  }}
                  onBlur={() => {
                    if (yesValue === "") {
                      const defaultYesValue = t("caymland.core.form.yes");
                      setYesValue(defaultYesValue);
                      setModifiedData((prevData) => ({
                        ...prevData,
                        properties: {
                          no: noValue,
                          yes: defaultYesValue,
                        },
                      }));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    case "number":
      return (
        <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
          <div className="flex gap-10" style={{ alignItems: "end" }}>
            <div className="w-50">
              <Select
                name="rounding"
                label={t("caymland.lead.field.form.properties.numberrounding")}
                isSearchable
                margin
                key={JSON.stringify(roundingMode)}
                value={roundingMode.find((r) => r?.value === parseInt(modifiedData?.properties?.roundmode))}
                options={roundingMode}
                placeholder={t("caymland.core.form.chooseone")}
                onChange={(e) => {
                  setModifiedData({
                    ...modifiedData,
                    properties: {
                      ...modifiedData.properties,
                      roundmode: e.value,
                    },
                  });
                }}
              />
            </div>
            <div className="w-50">
              <Text
                name={"precistion"}
                label={t("caymland.lead.field.form.properties.numberprecision")}
                type={"number"}
                marginBottom={true}
                value={modifiedData?.properties?.precision || ""}
                onChange={(e) =>
                  setModifiedData({
                    ...modifiedData,
                    properties: {
                      ...modifiedData.properties,
                      precision: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export default HelperComponent;
