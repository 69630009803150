import React, { useEffect, useMemo, useState } from "react";
import { useAuditLogTabQuery } from "../../../../../redux/api/contactsApi";
import CustomTable from "../../CustomTable/CustomTable";
import moment from "moment";
import { MRT_ToggleSelect } from "../../../../reusableComponents/CustomTable/MRT_ToggleSelect";
import { t } from "i18next";

const AuditLog = ({ id, setAuditLog }) => {
  const [activeRowId, setActiveRowId] = useState();
  const [query, setQuery] = useState({
    pageIndex: 0,
    pageSize: sessionStorage.getItem("pageSize") ? parseInt(sessionStorage.getItem("pageSize"), 10) : 30,
    orderByDir: "DESC",
    // orderBy: "timestamp",
  });
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const { data, isFetching } = useAuditLogTabQuery({ id, query, includeEvents: selectedEventTypes });

  useEffect(() => {
    if (data?.total) {
      setAuditLog(data.total);
    }
  }, [data]);

  const getEventTypeLabel = (eventType) => {
    // Perform checks and return the corresponding label
    if (eventType === "update") {
      return "Contact Updated";
    } else if (eventType === "create") {
      return "Contact Created";
    } else if (eventType === "delete") {
      return "Contact Deleted";
    } else if (eventType === "identified") {
      return "Contact Identified";
    } else if (eventType === "merge") {
      return "Contact Merged";
    } else {
      // Default label for unknown event types
      return "Unknown Event";
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "eventLabel",
        accessorFn: (row) => row.eventLabel,
        header: t("caymland.lead.timeline.user_name"),
        enableColumnActions: false,
      },
      {
        // Update this column definition
        accessorKey: "eventType",
        accessorFn: (row) => {
          // Use the getEventTypeLabel function to get the custom label
          const eventTypeLabel = getEventTypeLabel(row?.eventType);
          return eventTypeLabel;
        },
        header: t("caymland.lead.timeline.event_type"),
        enableColumnActions: false,
      },
      {
        accessorKey: "timestamp",
        accessorFn: (row) => {
          // Use Moment.js to format the timestamp
          return moment(row?.timestamp).format("DD.MM.YY HH:mm");
        },
        header: t("caymland.lead.timeline.event_timestamp"),
        enableColumnActions: false,
        muiTableHeadCellProps: {
          align: "right",
          sx: {
            verticalAlign: "bottom",
            zIndex: 0,
          },
        },
        muiTableBodyCellProps: {
          align: "right",
        },
      },
    ],
    []
  );

  let options = [];

  // Check if types is defined before trying to create the options array
  if (data && data?.types) {
    options = Object.entries(data.types).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }

  const handleEventTypeChange = (selectedOptions) => {
    // Assuming selectedOptions is an array of selected event types
    setSelectedEventTypes(selectedOptions);
  };
  console.log(selectedEventTypes);
  const toolbarButtons = useMemo(() => [
    {
      name: "auditlogs",
      component: MRT_ToggleSelect,
      props: {
        data: options,
        onChange: handleEventTypeChange,
        value: selectedEventTypes,
        title: "auditlogs",
        isMulti: true,
      },
    },
  ]);
  return (
    <div>
      <CustomTable
        data={data}
        columns={columns}
        query={query}
        setQuery={setQuery}
        showDetailPanel
        footer
        mediumHeight
        actions={false}
        toolbarButtons={toolbarButtons}
        isFetching={isFetching}
        linkNavigate={null}
        header
        setActiveRowId={setActiveRowId}
      />
    </div>
  );
};

export default AuditLog;
