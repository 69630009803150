import React, { useEffect, useState, useMemo } from "react";
import { Controller } from "react-hook-form";
import { ButtonGroup, Label, Text } from "../../../../reusableComponents/Inputs";
import IntervalMode from "../triggerModes/IntervalMode";
import DateMode from "../triggerModes/DateMode";
import { t } from "i18next";

const Components = {
  interval: IntervalMode,
  date: DateMode,
};

const Modes = React.memo(({ triggerMode, control, watch, fields }) => {
  const Component = Components[triggerMode];
  return Component ? <Component control={control} watch={watch} fields={fields} /> : null;
});

export default function DefaultFields({ fields, control, watch }) {
  const [mode, setMode] = useState({ choices: null });

  useEffect(() => {
    setMode(fields?.formDefaultFields?.triggerMode);
  }, [fields?.formDefaultFields?.triggerMode]);

  const triggerMode = watch("triggerMode");

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid #b1b1b1" }}>
        <Controller
          control={control}
          name="name"
          render={({ field: { name, onChange, value } }) => (
            <Text name={name} value={value} onChange={onChange} label={t("caymland.core.name")} />
          )}
        />
        {mode?.choices && (
          <div style={{ paddingBottom: 10 }}>
            <Controller
              control={control}
              name={"triggerMode"}
              rules={{ required: mode.required }}
              render={({ field: { name, onChange, value } }) => {
                const filteredButtons =
                  fields?.type === "lead.scorecontactscompanies" &&
                  (control?._formValues?.anchors === "no" || control?._formValues?.decisionPath === "no")
                    ? mode.choices.filter((button) => button.value !== "immediate")
                    : mode.choices;
                return (
                  <>
                    <Label
                      name={mode.name}
                      label={mode.label}
                      tooltip={mode.attr.tooltip}
                      required={filteredButtons.required}
                    />
                    <ButtonGroup
                      buttons={filteredButtons}
                      value={value}
                      name={name}
                      onChange={onChange}
                      field={fields}
                    />
                  </>
                );
              }}
            />

            <Modes triggerMode={triggerMode} control={control} watch={watch} fields={fields} />
          </div>
        )}
      </div>
    </>
  );
}
