import { t } from "i18next";
import React, { useMemo } from "react";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";

export const IncomingTypeReusible = (props) => {
  const { palette } = useTheme();

  const { field, handleOnChange, value, fields, loading, isDisabled } = props;
  const operatorNameMapping = {
    "=": "equals",
    "!=": "not_equal",
    gt: "greater_than",
    gte: "greater_than_or_equals",
    lt: "less_than",
    lte: "less_than_or_equals",
    empty: "empty",
    "!empty": "not_empty",
    like: "like",
    "!like": "not_like",
    regexp: "regexp",
    "!regexp": "not_regexp",
    startsWith: "starts_with",
    endsWith: "ends_with",
    contains: "contains",
    "!in": "excluding",
    in: "including",
  };

  const operatorsOptions = useMemo(() => {
    if (!field) return [];
    return fields.reduce((acc, item) => {
      if (item.options) {
        const foundOption = item.options.find((option) => option.name === field.field);
        if (foundOption) {
          acc = foundOption.operators.map((o) => {
            const translatedKey = operatorNameMapping[o.name] || o.name; // Use mapping if available, otherwise use original name
            return {
              value:
                o.name == "greater_than_or_equal"
                  ? "greater_than_or_equals"
                  : o.name == "less_than_or_equal"
                  ? "less_than_or_equals"
                  : o.name,
              label: t(`caymland.core.operator.${translatedKey}`),
            };
          });
        }
      }
      return acc;
    }, []);
  }, [field, fields]);

  const selectedValue = useMemo(() => {
    const matchingOption = operatorsOptions.find((o) => o.value === value);
    return matchingOption || null;
  }, [value, operatorsOptions]);

  const handleChange = (selectedOption) => {
    if (handleOnChange) {
      handleOnChange(selectedOption?.value);
    }
  };

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: palette?.primary?.main,
      primary: palette?.primary?.main,
    },
  });

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: "15px",
      height: "40px",
      transition: "transform 0.3s ease, box-shadow 0.3s ease",
      "&:hover": {
        transform: "scale(1.003)",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
        borderColor: "#5A5A5A",
      },
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "white",
    }),
    group: (provided) => ({
      ...provided,
      backgroundColor: "white",
      padding: "10px 2px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? palette?.primary?.lightPrimaryColor : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px 2px",
      fontSize: "15px",
      transition: "background-color 0.2s ease",
      paddingLeft: "20px",
      "&:active": {
        color: "white",
        backgroundColor: palette?.primary?.main,
      },
      "&:hover": {
        color: "white",
        backgroundColor: palette?.primary?.main,
      },
      "&:focus": {
        color: "white",
      },
    }),
  };

  return (
    <div style={{ width: "30%" }}>
      <Select
        isLoading={loading}
        isDisabled={isDisabled}
        options={operatorsOptions}
        placeholder={loading ? t("caymland.core.loading") : `${t("caymland.core.select")}...`}
        value={selectedValue}
        onChange={handleChange}
        theme={selectTheme}
        styles={selectStyles}
        menuPlacement="auto"
      />
    </div>
  );
};
