import React, { Fragment, useCallback, useEffect, useState } from "react";
import { FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import Loading from "../../../../reusableComponents/loading";
import DropZoneMask from "../../../../reusableComponents/DropZone/DropZoneMask";
import CustomModal from "../../../../reusableComponents/CustomModal/CustomModal";
import ContentGenerator from "../helpers/contentGenerator";
import { useForm, useController } from "react-hook-form";
import SidebarSkeleton from "../../../../reusableComponents/SidebarSkeleton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DatePicker, Select, Text, TextArea } from "../../../../reusableComponents/Inputs";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Button from "../../../../reusableComponents/Buttons/Button";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import { formsApi } from "../../../../../redux/api";
import { IconButton } from "@mui/material";
import { stubTrue } from "lodash";
import { transformQuery } from "react-querybuilder";
import { SlideAlert } from "../../../../reusableComponents/Alerts";
import { t } from "i18next";

const Fields = ({ fields, loading, setModifiedData, modifiedData, setFields }) => {
  const [showZoneMask, setShowZoneMask] = useState(false);
  const [type, setType] = useState(null);
  const [actionData, setActionData] = useState(null);
  const [actionFields, setActionFields] = useState({});
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    title: "",
    type: "",
    loading: false,
  });
  const [searchFields, setSearchFields] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [backgorundToast, setBackgroundToast] = useState("");

  const { handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {},
  });

  const filteredFields = fields.filter((field) => {
    if (!searchFields) return true;
    return (
      field.label.toLowerCase().includes(searchFields.toLowerCase()) ||
      field.value.toLowerCase().includes(searchFields.toLowerCase())
    );
  });

  const [triggerFields] = formsApi.useLazyGetFormsFieldsQuery();
  const closeModal = () => {
    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
      editData: null,
    }));
    setActionData(actionData);
  };

  const handleActionInfo = (item) => ({
    value: item.value,
    label: `${item.label}`,
  });

  const onDragStart = (event, nodeType) => {
    setShowZoneMask(true);
    event.dataTransfer.setData("application/reactflow", JSON.stringify(nodeType));
    event.dataTransfer.effectAllowed = "move";
    event.target.style.cursor = "move";
  };

  const onDragStop = () => {
    setShowZoneMask(false);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(async (event) => {
    event.preventDefault();
    const dragData = event.dataTransfer.getData("application/reactflow");

    if (dragData && dragData !== "undefined") {
      const field = JSON.parse(dragData);
      setType(field.value);
      setModalOptions((prev) => ({
        ...prev,
        isOpen: true,
        title: field.label,
        type: field.value,
        loading: true,
      }));

      const response = await triggerFields({ type: field.value });
      const responseData = response.data;

      let newGroupedData = {};
      let showLabelExists = false;

      for (const tabName in responseData) {
        if (tabName !== "type" && tabName !== "buttons") {
          const tabFields = responseData[tabName];
          for (const fieldName in tabFields) {
            if (fieldName !== "formId") {
              const fieldData = tabFields[fieldName];
              if (!newGroupedData[tabName]) {
                newGroupedData[tabName] = [];
              }
              newGroupedData[tabName].push({
                ...fieldData,
              });

              if (tabName === "generalTab" && fieldName === "showLabel") {
                showLabelExists = true;
              }
            }
          }
        }
      }

      setGroupedData(newGroupedData);
      setActionFields(newGroupedData);

      setActionData({
        showLabel: true,
        showWhenValueExists: true,
        saveResult: true,
        overwrittenFieldOnSubmit: true,
      });

      setModalOptions((prev) => ({
        ...prev,
        loading: false,
      }));

      setShowZoneMask(false);
    } else {
      console.error("Dragged data is undefined or invalid.");
    }
  }, []);
  const onSubmit = () => {
    if (actionData) {
      const newField = {
        tempFieldId: actionData.id || Math.floor(Math.random() * 100000),
        type: modalOptions.type,
        ...actionData,
      };

      if (!newField.label || newField.label.trim() === "") {
        setShow(true);
        setMessage(t("caymland.form.tab.actions.fieldempty"));
        setBackgroundToast("2");
        setTimeout(() => {
          setShow(false);
          setMessage("");
          setBackgroundToast("1");
        }, 8000);
        return;
      }

      if (newField.type === "file") {
        const allowedFileSize = newField.properties?.allowed_file_size;

        if (allowedFileSize) {
          const fileSize = parseFloat(allowedFileSize);

          if (isNaN(fileSize) || fileSize > 2) {
            setShow(true);
            setMessage(t("caymland.form.tab.actions.allowedsize"));
            setBackgroundToast("2");
            setTimeout(() => {
              setShow(false);
              setMessage("");
              setBackgroundToast("1");
            }, 8000);
            return;
          }
        } else {
          setShow(true);
          setMessage(t("caymland.form.tab.actions.maxsizelabel"));
          setBackgroundToast("2");
          setTimeout(() => {
            setShow(false);
            setMessage("");
            setBackgroundToast("1");
          }, 8000);
          return;
        }
      }

      if (newField.type === "pagebreak") {
        const nextPageLabel = newField.properties?.next_page_label;

        if (!nextPageLabel || nextPageLabel.trim() === "") {
          setShow(true);
          setMessage(t("caymland.form.tab.actions.buttonlabel"));
          setBackgroundToast("2");
          setTimeout(() => {
            setShow(false);
            setMessage("");
            setBackgroundToast("1");
          }, 8000);
          return;
        }
      }

      if (newField.type === "radiogrp" || newField.type === "select" || newField.type === "checkboxgrp") {
        let list;
        if (newField.type === "radiogrp" || newField.type === "checkboxgrp") {
          list = newField.properties?.optionlist?.list;
        } else if (newField.type === "select") {
          list = newField.properties?.list?.list;
        }

        if (!list || list.length === 0) {
          setShow(true);
          const messageText =
            newField.type === "radiogrp" || newField.type === "checkboxgrp"
              ? t("caymland.form.tab.actions.optionlist")
              : t("caymland.form.tab.actions.listfield");
          setBackgroundToast("2");
          setMessage(messageText);
          setTimeout(() => {
            setShow(false);
            setMessage("");
            setBackgroundToast("1");
          }, 8000);
          return;
        }

        const invalidItem = list.some(
          (item) =>
            !item?.label ||
            typeof item.label !== "string" ||
            item.label.trim() === "" ||
            !item?.value ||
            typeof item.value !== "string" ||
            item.value.trim() === ""
        );
        if (invalidItem) {
          setShow(true);
          setMessage(t("caymland.form.tab.actions.nonemptylabelvalue"));
          setBackgroundToast("2");
          setTimeout(() => {
            setShow(false);
            setMessage("");
            setBackgroundToast("1");
          }, 8000);
          return;
        }
      }

      if (actionData.id) {
        const updatedFields = modifiedData.fields.map((field) => (field.id === actionData.id ? newField : field));
        setModifiedData((prev) => ({
          ...prev,
          fields: updatedFields,
        }));
      } else {
        setModifiedData((prev) => ({
          ...prev,
          fields: [...prev.fields, newField],
        }));
      }
      if (actionData.tempFieldId) {
        const updatedFields = modifiedData.fields.map((field) =>
          field.tempFieldId === actionData.tempFieldId ? newField : field
        );
        setModifiedData((prev) => ({
          ...prev,
          fields: updatedFields,
        }));
      }
    }

    setModalOptions((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setActionFields({});
    setActionData(null);
  };

  const handleDelete = (e, item) => {
    e.stopPropagation();
    setModifiedData((prev) => ({
      ...prev,
      fields: prev.fields.filter((x) => x.id !== item.id || x.tempFieldId !== item.tempFieldId),
    }));
  };

  const handleEdit = async (item) => {
    setActionData(item);

    setModalOptions((prev) => ({
      ...prev,
      isOpen: true,
      title: `Edit ${item.label}`,
      type: item.type,
      editData: actionData,
      loading: true,
    }));

    const response = await triggerFields({ type: item?.type });
    const data = response.data;

    let newGroupedData = {};
    for (const tabName in data) {
      if (tabName !== "type" && tabName !== "buttons") {
        const tabFields = data[tabName];
        for (const fieldName in tabFields) {
          if (fieldName !== "formId") {
            const fieldData = tabFields[fieldName];
            if (!newGroupedData[tabName]) {
              newGroupedData[tabName] = [];
            }

            newGroupedData[tabName].push({
              ...fieldData,
            });
          }
        }
      }
    }
    setGroupedData(newGroupedData);

    setModalOptions((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(draggableFields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setModifiedData((prev) => ({
      ...prev,
      fields: [...items, ...buttonFields],
    }));
  };

  const draggableFields =
    modifiedData?.fields?.filter((field) => field?.type !== "button" && field?.label !== "additionalCSS") || [];
  const buttonFields = modifiedData?.fields?.filter((field) => field?.type === "button");

  return (
    <Fragment>
      <SlideAlert background={backgorundToast} message={message} show={show} setShowAlert={setShow} />
      <CustomModal
        modalOptions={modalOptions}
        handleSubmit={handleSubmit(onSubmit)}
        close={closeModal}
        saveText="Add"
        content={
          <ContentGenerator
            data={actionFields}
            groupedData={groupedData}
            control={control}
            type={type}
            setEntity={setActionData}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            entity={actionData}
            show={show}
          />
        }
      />

      {loading ? (
        <div className="flex items-center justify-center">
          <SidebarSkeleton applyPadding />
        </div>
      ) : (
        <div className={`flex items-start justfiy-between gap-20`} style={{ padding: "16px" }}>
          <div className="" style={{ width: "30%" }}>
            <Text
              name="searchFields"
              value={searchFields}
              onChange={(e) => setSearchFields(e.target.value)}
              label={t("caymland.core.multiselect.search")}
            />
            <div
              className="accordion-list"
              style={{
                position: "relative",
                overflowY: "scroll",
              }}
            >
              {filteredFields?.map((item, index) => (
                <div
                  onDragStart={(event) => onDragStart(event, handleActionInfo(item))}
                  onDragEnd={(e) => onDragStop(e)}
                  key={index}
                  className={`accordion-list-item draggable-item`}
                  draggable
                  id="dragme"
                >
                  <div
                    className={`d-flex flex-wrap items-center justify-between mid-gap-15 cursor-grab 
                    }`}
                  >
                    {item?.label}
                    <i>
                      <IconButton>
                        <DragHandleIcon style={{ color: "#757575", cursor: "grab" }} />
                      </IconButton>
                    </i>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`flex-1  relative fields-container `}
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => onDragOver(e)}
            style={{ alignSelf: "stretch" }}
          >
            {showZoneMask && <DropZoneMask title="field" />}
            <DragDropContext onDragEnd={onDragEnd}>
              {draggableFields && draggableFields.length > 0 && (
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided?.droppableProps} ref={provided.innerRef} className=" flex-1 h-full relative ">
                      {draggableFields.map((field, index) => {
                        return (
                          <Draggable
                            key={field.id ? field.id.toString() : field.tempFieldId.toString()}
                            draggableId={field.id ? field.id.toString() : field.tempFieldId.toString()}
                            index={index}
                            isDragDisabled={field.type === "button"}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="actions-cards"
                                onMouseEnter={() => setHoveredItem(index)}
                                onMouseLeave={() => setHoveredItem(null)}
                              >
                                <div
                                  className={hoveredItem === index ? `action-cards-card w-100` : " w-100"}
                                  onClick={() => handleEdit(field)}
                                >
                                  {field.type === "select" || field.type === "country"
                                    ? (console.log(field),
                                      (
                                        <span className="grid" style={{ width: "100%" }}>
                                          <Select
                                            isDisabled
                                            key={field.showLabel}
                                            placeholder={
                                              field?.properties?.empty_value
                                                ? field.properties?.empty_value
                                                : t("caymland.core.select")
                                            }
                                            {...(field.showLabel ? { label: field?.label } : { label: "." })}
                                          />
                                          {field.leadField && (
                                            <span
                                              style={{
                                                width: "100%",
                                                textTransform: "capitalize",
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "2px",
                                              }}
                                            >
                                              <PeopleOutlinedIcon fontSize="small" />
                                              {field?.leadField}
                                            </span>
                                          )}
                                        </span>
                                      ))
                                    : null}
                                  {field.type === "plugin.recaptcha" && (
                                    <img
                                      src="https://miro.medium.com/v2/resize:fit:1156/1*O0hPhfithgkGiY-mm_ON0Q.png"
                                      alt="reCAPTCHA"
                                      style={{ marginRight: "10px", width: "280px" }}
                                    />
                                  )}
                                  {field.type === "file" ? (
                                    <span className="grid" style={{ width: "100%" }}>
                                      <span>{field?.label}</span>
                                      <input
                                        type="file"
                                        disabled
                                        className="form-control"
                                        style={{ fontSize: "14px" }}
                                      />
                                      {field.leadField && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            // borderTop: "1px solid gainsboro",
                                            // borderBottom: "1px solid gainsboro",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </span>
                                  ) : null}
                                  {field.type === "freehtml" || field.type === "freetext" ? (
                                    <span className="grid" style={{ width: "100%" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : null}
                                      <span>{field?.properties?.text}</span>
                                      {field.leadField && hoveredItem === index && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </span>
                                  ) : null}
                                  {field.type === "plugin.brochures" ||
                                  field.type === "hidden" ||
                                  field.type === "captcha" ||
                                  field.type === "plugin.loginSocial" ? (
                                    <span className="grid" style={{ width: "100%" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : null}
                                      {field.leadField && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </span>
                                  ) : null}
                                  {field.type === "text" ||
                                  field.type === "number" ||
                                  field.type === "tel" ||
                                  field.type === "password" ||
                                  field.type === "email" ||
                                  field.type === "url" ? (
                                    <span className="grid" style={{ width: "100%", gap: "2px" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : <>&nbsp;</>}
                                      <Text disabled placeholder={field?.placeholder || field?.defaultValues} />
                                      {field.leadField && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </span>
                                  ) : null}

                                  {field.type === "textarea" ? (
                                    <span className="grid" style={{ width: "100%" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : null}
                                      <input style={{ height: "100px" }} disabled type="textarea" />
                                      {field.leadField && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </span>
                                  ) : null}
                                  {(field.type === "date" || field.type === "datetime") && (
                                    <span style={{ width: "100%" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : <>&nbsp;</>}
                                      <DatePicker
                                        disabled
                                        placeholderText={field.type === "date" ? "d.m.Y" : "d.m.Y h:m"}
                                        dateFormat={field.type === "date" ? "d.m.Y" : "d.m.Y h:m"}
                                      />
                                      {field.leadField && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </span>
                                  )}
                                  {field.type === "radiogrp" && (
                                    <div className="grid" style={{ width: "100%" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : <>&nbsp;</>}
                                      {field?.properties?.optionlist?.list.map((item) => (
                                        <label
                                          key={item.id}
                                          style={{ display: "flex", alignItems: "center", gap: "4px" }}
                                        >
                                          <input type="radio" value={item.value} disabled />
                                          {item.label}
                                        </label>
                                      ))}
                                      {field.leadField && hoveredItem === index && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  {field.type === "checkboxgrp" && (
                                    <div className="grid" style={{ width: "100%" }}>
                                      {field.showLabel ? <span>{field?.label}</span> : null}
                                      {field?.properties?.optionlist?.list.map((item) => (
                                        <label
                                          key={item.id}
                                          style={{ display: "flex", alignItems: "center", gap: "4px" }}
                                        >
                                          <input type="checkbox" value={item.value} disabled />
                                          <div dangerouslySetInnerHTML={{ __html: item.label }} />
                                        </label>
                                      ))}
                                      {field.leadField && hoveredItem === index && (
                                        <span
                                          style={{
                                            width: "100%",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "2px",
                                          }}
                                        >
                                          <PeopleOutlinedIcon fontSize="small" />
                                          {field?.leadField}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  {field.type === "pagebreak" && (
                                    <div className="grid" style={{ width: "100%" }}>
                                      {field?.properties?.next_page_label ? (
                                        <div className="d-flex gap-5px">
                                          {field?.properties?.prev_page_label && (
                                            <Button buttons={[{ title: field?.properties?.prev_page_label }]} />
                                          )}
                                          <Button buttons={[{ title: field?.properties?.next_page_label }]} />
                                        </div>
                                      ) : field?.properties?.prev_page_label ? (
                                        <div className="d-flex gap-5px">
                                          <Button buttons={[{ title: field?.properties?.prev_page_label }]} />
                                          <Button buttons={[{ title: "Continue" }]} />
                                        </div>
                                      ) : (
                                        <div className="d-flex gap-5px">
                                          <Button buttons={[{ title: "Continue" }]} />
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  {hoveredItem === index && (
                                    <div className="d-flex items-center gap-5px">
                                      <div className="action-cards-button">
                                        <FaEdit
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation(), handleEdit(field);
                                          }}
                                          color="#135f95"
                                        />
                                      </div>
                                      <div
                                        className="action-cards-button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          handleDelete(e, field);
                                        }}
                                      >
                                        <FaTrashAlt color="#f86b4f" />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                      {buttonFields.map((field, index) => (
                        <div
                          key={field.id || field.tempFieldId}
                          className="actions-cards"
                          onMouseEnter={() => setHoveredButton(index)}
                          onMouseLeave={() => setHoveredButton(null)}
                        >
                          <div
                            className={hoveredButton === index ? ` action-cards-card` : ""}
                            onClick={() => handleEdit(field)}
                          >
                            <span style={{ width: "100%" }}>
                              <Button buttons={[{ title: field?.label }]} />
                            </span>
                            {hoveredButton === index && (
                              <div className="d-flex gap-5px ">
                                <div className="action-cards-button">
                                  <FaEdit
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation(), handleEdit(field);
                                    }}
                                    color="#135f95"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Droppable>
              )}
            </DragDropContext>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Fields;
